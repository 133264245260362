<!-- <div class="chat-message-section"   *ngIf="chatListhow" > -->

<div class="chat-message-section" *ngIf="chatListhow">
  <div *ngIf="selectedUserData._id" class="chat-message-header">
    <!-- <div class="back" (click)="showDiv()">BACK</div> -->

    <div class="member-item-img modification" style="cursor: pointer">
      <img
        [src]="
          selectedUserData.profile_image != ''
            ? selectedUserData.profile_image
            : 'assets/imgs/profile-img.png'
        "
        class="img-circle"
        style="border-radius: 50%; width: 50px; height: 50px"
        (click)="openDialogWithTemplateRef(userProfile)"
      />
    </div>
    <div class="member-info">
      <h3 *ngIf="selectedUser != '' && selectedThread == ''">
        {{ selectedUserData.first_name }} {{ selectedUserData.last_name }}
      </h3>
      <!-- <h3 *ngIf="selectedThread!='' && selectedUser=='' " >{{ chatDetails.participants['0']?.user_id?.full_name }}</h3> -->

      <p *ngIf="onlineUsers.includes(userProfileDetails._id)">
        Active Now <span class="circle-badge online"> </span>
      </p>
    </div>
    <a
      *ngIf="onlineUsers.includes(userProfileDetails._id) && enableCall"
      style="cursor: pointer"
      (click)="startCall(userProfileDetails._id)"
      class="member-call-icon"
    >
      <i class="call-icon"></i>
    </a>
    <!-- {{enableCall}} -->
    <a
      *ngIf="!onlineUsers.includes(userProfileDetails._id) || !enableCall"
      (click)="showCallMessage(userProfileDetails._id)"
      style="cursor: pointer"
      class="member-call-icon"
    >
      <i class="call-icon"></i>
    </a>
  </div>

  <!--chat-message-header-->

  <!-- <div class="chat-message-area" #scrollMe> -->
  <div class="chat-message-area" #scrollMe>
    <div
      class="msg_history"
      *ngFor="
        let conversation of chatDetails?.conversation;
        let odInd = odd;
        let evInd = even
      "
    >
      <ng-container *ngIf="!conversation.type || conversation.type == 'CHAT'">
        <div class="incoming_msg" *ngIf="!conversation.sent_by_me">
          <div class="incoming_msg_img">
            <img
              src="{{
                conversation.sender_id.profile_image != ''
                  ? conversation.sender_id.profile_image
                  : 'assets/imgs/profile-img.png'
              }} "
              alt="sunil"
            />
          </div>
          <div class="received_msg">
            <p class="time_date">
              {{ conversation.sender_id.first_name }},
              <span>{{ conversation.createdAt }}</span>
            </p>
            <div *ngIf="evInd" class="received_withd_msg">
              <div *ngIf="conversation.chat_files.length > 0">
                <div *ngFor="let img of conversation.chat_files">
                  <ng-container
                    *ngIf="
                      img.is_paid == 1 &&
                      userProfileDetails.user_type == 'CELEBRITY'
                    "
                  >
                    <img
                      *ngIf="
                        img.file_type == 'png' ||
                        img.file_type == 'jpg' ||
                        img.file_type == 'jpeg'
                      "
                      [src]="img.file_link"
                      height="150"
                      width="200"
                    />
                    <video
                      *ngIf="img.file_type == 'mp4'"
                      width="200"
                      height="100"
                      controls
                    >
                      <source [src]="img.file_link" type="video/mp4" />
                    </video>
                    <a
                      *ngIf="
                        img.file_type == 'doc' ||
                        img.file_type == 'docx' ||
                        img.file_type == 'xls' ||
                        img.file_type == 'xlsx' ||
                        img.file_type == 'pdf'
                      "
                      [href]="img.file_link"
                      target="_blank"
                    >
                      <img
                        *ngIf="
                          img.file_type == 'doc' || img.file_type == 'docx'
                        "
                        src="assets/imgs/doc_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="img.file_type == 'pdf'"
                        src="assets/imgs/pdf_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="
                          img.file_type == 'xls' || img.file_type == 'xlsx'
                        "
                        src="assets/imgs/excel_icon.png"
                        width="70"
                        height="70"
                      />
                      {{ img.file_name }}
                    </a>
                  </ng-container>

                  <!-- <div *ngIf="img.is_paid == 0  && userProfileDetails.user_type == 'CELEBRITY'"> <img src="assets/imgs/lock.png" 
                                    alt="sunil"  height="100" width="200" (click)="setpopup()">
                                </div> -->
                  <ng-container
                    *ngIf="
                      img.is_paid == 0 &&
                      userProfileDetails.user_type == 'CELEBRITY'
                    "
                  >
                    <img
                      src="assets/imgs/lock.png"
                      alt=""
                      height="100"
                      width="200"
                      (click)="paymentConfirmDialog(payment, img)"
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="userProfileDetails.user_type == 'REGULAR'"
                  >
                    <img
                      *ngIf="
                        img.file_type == 'png' ||
                        img.file_type == 'jpg' ||
                        img.file_type == 'jpeg'
                      "
                      [src]="img.file_link"
                      height="100"
                      width="200"
                    />
                    <video
                      *ngIf="img.file_type == 'mp4'"
                      width="200"
                      height="100"
                      controls
                    >
                      <source [src]="img.file_link" type="video/mp4" />
                    </video>
                    <a
                      *ngIf="
                        img.file_type == 'doc' ||
                        img.file_type == 'docx' ||
                        img.file_type == 'xls' ||
                        img.file_type == 'xlsx' ||
                        img.file_type == 'pdf'
                      "
                      [href]="img.file_link"
                      target="_blank"
                    >
                      <img
                        *ngIf="
                          img.file_type == 'doc' || img.file_type == 'docx'
                        "
                        src="assets/imgs/doc_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="img.file_type == 'pdf'"
                        src="assets/imgs/pdf_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="
                          img.file_type == 'xls' || img.file_type == 'xlsx'
                        "
                        src="assets/imgs/excel_icon.png"
                        width="70"
                        height="70"
                      />
                      {{ img.file_name }}
                    </a>
                  </ng-container>
                </div>
              </div>

              <p
                style="white-space: pre-wrap"
                *ngIf="conversation.chat_content != ''"
              >
                {{ conversation.chat_content }}
              </p>
            </div>
            <div *ngIf="odInd" class="received_withd_msg">
              <!-- <p>How are you?</p> -->
              <!-- <div *ngIf="conversation.chat_files.length > 0 ">
                                <div *ngFor="let img of conversation.chat_files">
                                    <img *ngIf="img.file_type == 'png' || img.file_type == 'jpg' || img.file_type == 'jpeg' && img.is_paid == 1 "
                                        [src]="img.file_link" height="100" width="200">
                                    <video *ngIf="img.file_type == 'mp4' && img.is_paid == 1" width="200" height="100" controls>
                                        <source [src]="img.file_link" type="video/mp4">
                                    </video>
                                    <a *ngIf=" img.file_type == 'doc' || img.file_type == 'docx' || img.file_type == 'xls'|| img.file_type == 'xlsx' || img.file_type == 'pdf'&& img.is_paid == 1 "
                                        [href]="img.file_link" target="_blank">
                                        <img *ngIf=" img.file_type == 'doc' || img.file_type == 'docx'&& img.is_paid == 1 "
                                            src="assets/imgs/doc_icon.png" width="70" height="70">
                                        <img *ngIf=" img.file_type == 'pdf'&& img.is_paid == 1 " src="assets/imgs/pdf_icon.png" width="70"
                                            height="70">
                                        <img *ngIf=" img.file_type == 'xls' || img.file_type == 'xlsx' && img.is_paid == 1"
                                            src="assets/imgs/excel_icon.png" width="70" height="70">
                                        {{img.file_name}}
                                    </a>
                                </div>
                            </div>
                            <p style="white-space: pre-wrap;" *ngIf="conversation.chat_content!= '' ">
                                {{conversation.chat_content}}</p> -->
              <div *ngIf="conversation.chat_files.length > 0">
                <div *ngFor="let img of conversation.chat_files">
                  <ng-container
                    *ngIf="
                      img.is_paid == 1 &&
                      userProfileDetails.user_type == 'CELEBRITY'
                    "
                  >
                    <img
                      *ngIf="
                        img.file_type == 'png' ||
                        img.file_type == 'jpg' ||
                        img.file_type == 'jpeg'
                      "
                      [src]="img.file_link"
                      height="150"
                      width="200"
                    />
                    <video
                      *ngIf="img.file_type == 'mp4'"
                      width="200"
                      height="100"
                      controls
                    >
                      <source [src]="img.file_link" type="video/mp4" />
                    </video>
                    <a
                      *ngIf="
                        img.file_type == 'doc' ||
                        img.file_type == 'docx' ||
                        img.file_type == 'xls' ||
                        img.file_type == 'xlsx' ||
                        img.file_type == 'pdf'
                      "
                      [href]="img.file_link"
                      target="_blank"
                    >
                      <img
                        *ngIf="
                          img.file_type == 'doc' || img.file_type == 'docx'
                        "
                        src="assets/imgs/doc_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="img.file_type == 'pdf'"
                        src="assets/imgs/pdf_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="
                          img.file_type == 'xls' || img.file_type == 'xlsx'
                        "
                        src="assets/imgs/excel_icon.png"
                        width="70"
                        height="70"
                      />
                      {{ img.file_name }}
                    </a>
                  </ng-container>

                  <!-- <div *ngIf="img.is_paid == 0  && userProfileDetails.user_type == 'CELEBRITY'"> <img src="assets/imgs/lock.png" 
                                        alt="sunil"  height="100" width="200" (click)="setpopup()">
                                    </div> -->
                  <ng-container
                    *ngIf="
                      img.is_paid == 0 &&
                      userProfileDetails.user_type == 'CELEBRITY'
                    "
                  >
                    <img
                      src="assets/imgs/lock.png"
                      alt=""
                      height="100"
                      width="200"
                      (click)="paymentConfirmDialog(payment, img)"
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="userProfileDetails.user_type == 'REGULAR'"
                  >
                    <img
                      *ngIf="
                        img.file_type == 'png' ||
                        img.file_type == 'jpg' ||
                        img.file_type == 'jpeg'
                      "
                      [src]="img.file_link"
                      height="150"
                      width="200"
                    />
                    <video
                      *ngIf="img.file_type == 'mp4'"
                      width="200"
                      height="100"
                      controls
                    >
                      <source [src]="img.file_link" type="video/mp4" />
                    </video>
                    <a
                      *ngIf="
                        img.file_type == 'doc' ||
                        img.file_type == 'docx' ||
                        img.file_type == 'xls' ||
                        img.file_type == 'xlsx' ||
                        img.file_type == 'pdf'
                      "
                      [href]="img.file_link"
                      target="_blank"
                    >
                      <img
                        *ngIf="
                          img.file_type == 'doc' || img.file_type == 'docx'
                        "
                        src="assets/imgs/doc_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="img.file_type == 'pdf'"
                        src="assets/imgs/pdf_icon.png"
                        width="70"
                        height="70"
                      />
                      <img
                        *ngIf="
                          img.file_type == 'xls' || img.file_type == 'xlsx'
                        "
                        src="assets/imgs/excel_icon.png"
                        width="70"
                        height="70"
                      />
                      {{ img.file_name }}
                    </a>
                  </ng-container>
                </div>
              </div>

              <p
                style="white-space: pre-wrap"
                *ngIf="conversation.chat_content != ''"
              >
                {{ conversation.chat_content }}
              </p>
            </div>

            <!-- <div *ngIf="odInd" class="received_withd_msg">
                             
                            <p style="white-space: pre-wrap;" *ngIf="conversation.chat_content!= '' ">
                                {{conversation.chat_content}}</p>
                        </div> -->
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!conversation.type || conversation.type == 'CHAT'">
        <div class="outgoing_msg" *ngIf="conversation.sent_by_me">
          <p class="time_date">
            <span>{{ conversation.createdAt }}</span>
          </p>
          <div class="sent_msg">
            <div *ngIf="conversation.chat_files.length > 0">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: space-between;
                "
                *ngFor="let img of conversation.chat_files"
              >
                <img
                  *ngIf="
                    img.file_type == 'png' ||
                    img.file_type == 'jpg' ||
                    img.file_type == 'jpeg'
                  "
                  [src]="img.file_link"
                  height="150"
                  width="200"
                /><br />
                <video
                  *ngIf="img.file_type == 'mp4'"
                  width="200"
                  height="100"
                  controls
                >
                  <source [src]="img.file_link" type="video/mp4" />
                </video>
                <!--
                                <a *ngIf=" img.file_type == 'doc' || img.file_type == 'docx' || img.file_type == 'xls'|| img.file_type == 'xlsx' || img.file_type == 'pdf' "
                                    [href]="img.file_link" target="_blank">
                                    <img *ngIf=" img.file_type == 'doc' || img.file_type == 'docx' "
                                        src="assets/imgs/doc_icon.png" width="70" height="70">
                                    <img *ngIf=" img.file_type == 'pdf' " src="assets/imgs/pdf_icon.png" width="70"
                                        height="70">
                                    <img *ngIf=" img.file_type == 'xls' || img.file_type == 'xlsx'"
                                        src="assets/imgs/excel_icon.png" width="70" height="70">
                                    {{img.file_name}}
                                </a>
                            -->
              </div>
            </div>

            <p
              style="white-space: pre-wrap"
              *ngIf="conversation.chat_content != ''"
            >
              {{ conversation.chat_content }}
            </p>
          </div>
        </div>
      </ng-container>
      <div
        *ngIf="conversation.type == 'CALL'"
        class="call_duration_info text-center"
      >
        <p class="time_date">
          <span>{{ conversation.call_start_time }}</span>
        </p>
        <p class="time_date">
          Call, <span>{{ conversation.call_duration }}</span>
        </p>
      </div>
      <!-- <div class="msg_separate_line">
                <p>Today</p>
            </div> -->
    </div>
  </div>
  <!--chat-message-area-->
  <form
    *ngIf="selectedUserData._id"
    #msgForm
    id="msgForm"
    (submit)="sendMessage()"
  >
    <div class="chat-message-write">
      <div class="cmw-left">
        <div *ngIf="images.length > 0" class="files-select-list">
          <div class="fs-item-col" *ngFor="let img of images; let i = index">
            <i
              class="fs-cancel-icon"
              aria-hidden="true"
              style="cursor: pointer"
              (click)="removeImage(i)"
            ></i>

            <img
              *ngIf="img.ext == 'png' || img.ext == 'jpg' || img.ext == 'jpeg'"
              [src]="img.src"
            />

            <div
              *ngIf="img.ext == 'mp4' || img.ext == 'mkv'"
              class="fs-file-box"
            >
              <img src="assets/imgs/file.png" />
              <p>{{ img.fileName }}</p>
              <!-- <span>{{file.size}}</span> -->
            </div>
          </div>
        </div>

        <div *ngIf="!disableChat" class="cmw-emoji-with-textarea">
          <!-- <button class="btn-cmw"><i class="emoji-icon" ></i></button> -->
          <emoji-mart
            (emojiSelect)="addEmoji($event)"
            set="twitter"
            [style]="{ position: 'absolute', bottom: '90px', right: '500px' }"
            *ngIf="showEmojiPicker"
            sheetSize="16"
            title="Pick your emoji…"
          ></emoji-mart>

          <button type="button" class="btn-cmw">
            <i class="emoji-icon" (click)="toggleEmojiPicker()"></i>
          </button>

          <textarea
            (keydown)="resizeTextEditor($event)"
            rows="1"
            placeholder="Type a message"
            [(ngModel)]="chat_content"
            name="chat_content"
            #msgTextArea
          ></textarea>
        </div>
        <div *ngIf="disableChat" class="cmw-emoji-with-textarea">
          <!-- <button class="btn-cmw"><i class="emoji-icon" ></i></button> -->

          <textarea
            disabled
            rows="1"
            [placeholder]="placeholder_data"
            name="chat_content"
            #msgTextArea
          ></textarea>
        </div>
      </div>
      <!--
           <popup>
            Demo text
           </popup>
            -->
      <div *ngIf="sendFile" class="cmw-right mertb-10" id="chat-message-photo">
        <input
          id="filech"
          style="display: none"
          type="file"
          (change)="onFileChanged($event)"
          #fileInput
          multiple
        />
        <ng-container *ngIf="jsondata.user_type != 'CELEBRITY'">
          <button type="button" class="btn-cmw" (click)="fileInput.click()">
            <i class="photo-icon"></i>
          </button>
        </ng-container>
        <ng-container *ngIf="jsondata.user_type == 'CELEBRITY'">
          <!-- <button type="button" class="btn-cmw" (click)="openoption()"><i class="photo-icon"></i></button> -->
          <button
            type="button"
            class="btn-cmw"
            (click)="openDialogWithTemplateRef(myDialog)"
          >
            <i class="photo-icon"></i>
          </button>
        </ng-container>
      </div>
      <div
        *ngIf="!sendFile"
        class="cmw-right mertb-10"
        id="chat-message-submit"
      >
        <button type="submit" class="btn-cmw"><i class="send-icon"></i></button>
      </div>
    </div>
  </form>
</div>

<!--chat-message-section-->

<!-- Video call section start-->

<div class="video-call-wrap" *ngIf="videoCallshow && !outgoingCall">
  <div class="row video-group">
    <div id="remote-view" class="remote_view_opt">
      <p id="remote-player-name" class="player-name2"></p>
      <div id="remote-player" class="player">
        <!-- <img id="remoteUserIc" src="../../../../assets/imgs/user-Icon.svg" /> -->
        <!-- <img src="{{remoteUserInfo.profile_image}}"  alt="" srcset=""> -->
      </div>
    </div>

    <div class="remote_player_sec">
      <p id="local-player-name" class="player-name"></p>
      <div id="local-player" class="player"></div>
    </div>

    <!-- <div class="w-100"></div> -->
    <!-- <div class="col">
          <div id="remote-playerlist"></div>
        </div> -->
  </div>
  <!-- <video #remoteVideo autoplay playsinline src="assets/video-placeholder.jpg"></video> -->
  <div class="video-call-bottom">
    <div *ngIf="remotePlayerType == '' || remotePlayerType === 'REGULAR'">
      <!-- <button class="cmn-btn" (click)="clickMicrophoneEvent()"
                [ngClass]="microphoneToggle ? 'mute-icon' : 'unmute-icon'"></button> -->
      <button
        class="cmn-btn"
        (click)="muteMic()"
        [ngClass]="microphoneToggle ? 'mute-icon' : 'unmute-icon'"
      ></button>
      <button
        class="cmn-btn call-end-icon"
        (click)="clickcallendEvent(userProfileDetails._id, videoCallInfo.type)"
      ></button>
      <!-- <button class="cmn-btn" (click)="clickVideoEvent()"
                [ngClass]="videoToggle ? 'video-off-icon' : 'video-icon'"></button> -->
      <button
        class="cmn-btn"
        (click)="muteVideo()"
        [ngClass]="videoToggle ? 'video-off-icon' : 'video-icon'"
      ></button>
      <!-- <button   class="cmn-btn tips-icon" (click)="muteMic()"></button> -->
      <button class="cmn-btn" (click)="togglePiP()"></button>
    </div>

    <div *ngIf="remotePlayerType === 'CELEBRITY'">
      <!-- <button class="cmn-btn" (click)="clickMicrophoneEvent()"
                [ngClass]="microphoneToggle ? 'mute-icon' : 'unmute-icon'"></button> -->
      <button
        class="cmn-btn"
        (click)="muteMic()"
        [ngClass]="microphoneToggle ? 'mute-icon' : 'unmute-icon'"
      ></button>
      <button
        class="cmn-btn call-end-icon"
        (click)="clickcallendEvent(userProfileDetails._id, videoCallInfo.type)"
      ></button>
      <!-- <button class="cmn-btn" (click)="clickVideoEvent()"
                [ngClass]="videoToggle ? 'video-off-icon' : 'video-icon'"></button> -->
      <button
        class="cmn-btn"
        (click)="muteVideo()"
        [ngClass]="videoToggle ? 'video-off-icon' : 'video-icon'"
      ></button>
      <button class="cmn-btn tips-icon" (click)="opentips()"></button>
    </div>
  </div>
</div>

<!-- Dialog template -->
<ng-template #myDialog>
  <!-- <h2 matDialogTitle>Dialog!</h2> -->
  <mat-dialog-content class="popupoverview">
    <div class="popupwrap_info">
      <h3 class="popupheading">Select Image Type</h3>

      <div *ngIf="!typecheck">
        <div class="redio_opt_view">
          <div class="redio_opt_item">
            <input
              type="radio"
              id="free"
              [(ngModel)]="type"
              (change)="openFileBrowse()"
              name="image_type"
              value="1"
              matDialogClose
            />
            <label style="color: white">Free</label>
          </div>
          <div class="redio_opt_item">
            <input
              [(ngModel)]="type"
              type="radio"
              id="paid"
              name="image_type"
              value="0"
              (change)="openDialogWithTemplateRef(imgprice)"
              matDialogClose
            />
            <label style="color: white">Paid</label>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
      <button mat-button matDialogClose color="primary">Dismiss</button> 
    </mat-dialog-actions> -->
</ng-template>

<!-- Dialog template to capture price of paid image -->
<ng-template #imgprice>
  <!-- <h2 matDialogTitle>Dialog!</h2> -->
  <mat-dialog-content>
    <form>
      <div class="inp_row_wrap">
        <input
          [(ngModel)]="price"
          type="text"
          placeholder="Enter your price"
          name="price"
          required
        />
        <button type="submit" matDialogClose (click)="openFileBrowse()">
          submit
        </button>
      </div>
    </form>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end"> -->
  <!-- <button mat-button matDialogClose color="primary">Dismiss</button> -->
  <!-- </mat-dialog-actions> -->
</ng-template>

<ng-template #payment>
  <!-- <h2 matDialogTitle>Dialog!</h2> -->
  <mat-dialog-content>
    <b style="color: white">Field Locked</b>
    <br /><br />
    <p style="color: white">
      To Unlock this file you need to pay ${{ paymentInfo.price }}.Are you sure
      you want to proceed ?
    </p>

    <br />
    <button type="submit" matDialogClose (click)="cancelPayment()">
      Cancel
    </button>
    <button
      type="submit"
      matDialogClose
      (click)="confirmPayment()"
      style="float: right"
    >
      Yes
    </button>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button mat-button matDialogClose color="primary">Dismiss</button> -->
  </mat-dialog-actions>
</ng-template>

<!-- Video call section end-->

<!-- <app-call-section></app-call-section> -->

<!-- to shoew incoming call  -->
<div class="video-call-wrap1" *ngIf="incomingCall">
  <div class="video_inf">
    <div class="video-call-user">
      <img src="{{ remoteUserInfo.profile_image }}" alt="" srcset="" />

      <!-- <video #localVideo id="my_video" autoplay playsinline></video> -->
    </div>

    <div class="video-call-user-name">
      <p>{{ remoteUserInfo.first_name }} {{ remoteUserInfo.last_name }}</p>
    </div>
  </div>

  <video
    #remoteVideo
    autoplay
    playsinline
    src="assets/video-placeholder.jpg"
  ></video>
  <div class="video-call-bottom">
    <div>
      <button
        class="cmn-btn call-start-icon"
        (click)="acceptCall(userProfileDetails._id)"
      ></button>
      <!-- <button class="cmn-btn call-start-icon" (click)="join(videoCallInfo.sender_id)">
            </button> -->
      <button
        class="cmn-btn call-end-icon"
        (click)="rejectCall(userProfileDetails._id)"
      ></button>
    </div>
  </div>
</div>

<!-- to shoew outgoing call  -->
<div class="video-call-wrap1" *ngIf="videoCallshow && outgoingCall">
  <div class="video_inf">
    <div class="video-call-user">
      <!-- <img src="{{selectedUserData.profile_image}}"  alt="" srcset=""> -->

      <img
        *ngIf="selectedUserData.profile_image"
        [src]="selectedUserData.profile_image"
        alt=""
        srcset=""
      />
      <img
        *ngIf="!selectedUserData.profile_image"
        style="margin-left: -3px"
        src="assets/imgs/profile-img.png"
        alt=""
        srcset=""
      />

      <!-- <video #localVideo id="my_video" autoplay playsinline></video> -->
    </div>

    <div class="video-call-user-name">
      <p>{{ selectedUserData.first_name }} {{ selectedUserData.last_name }}</p>
    </div>
  </div>

  <video
    #remoteVideo
    autoplay
    playsinline
    src="assets/video-placeholder.jpg"
  ></video>
  <div class="video-call-bottom">
    <div>
      <!-- <button class="cmn-btn call-start-icon" (click)="acceptCall(userProfileDetails._id)">
            </button> -->
      <!-- <button class="cmn-btn call-start-icon" (click)="join(videoCallInfo.sender_id)">
            </button> -->
      <button
        class="cmn-btn call-end-icon"
        (click)="clickcallendEvent(userProfileDetails._id, videoCallInfo.type)"
      ></button>
    </div>
  </div>
</div>

<!-- //freelancer html to show video -->

<!--  start of popup for user profile -->

<ng-template #userProfile>
  <div class="cmn-shadow-bx" style="width: 300px">
    <!-- <div class="cmn-shadow-head">
            <span class="back-arrow" (click)="backToMain()"></span>
            <h4>User Profile</h4>
        </div> -->
    <div class="profile-block-wrap">
      <!-- <div class="profile-img-block" [style.background-image]="(selectedUserData.profile_image != '')? selectedUserData.profile_image : 'assets/imgs/profile-img.png'">
                <i class="camera-icon">
                </i>
            </div> -->
      <img
        [src]="
          selectedUserData.profile_image != ''
            ? selectedUserData.profile_image
            : 'assets/imgs/profile-img.png'
        "
        class="img-circle profile-img-block"
        style="border-radius: 50%; width: 50px; height: 50px"
      />

      <div class="profile-block-info">
        <h3>{{ userProfileDetails.full_name }}</h3>
        <!-- <p>{{userProfileDetails.email}}</p> -->
      </div>
      <div class="profile-block-info">
        <a (click)="goToLink(userProfileDetails.facebook)">
          <i
            *ngIf="userProfileDetails.facebook != ''"
            class="fa fa-facebook-square"
            aria-hidden="true"
            >&nbsp;&nbsp;</i
          >
        </a>
        <a (click)="goToLink(userProfileDetails.twitter)">
          <i
            *ngIf="userProfileDetails.twitter != ''"
            class="fa fa-twitter"
            aria-hidden="true"
            >&nbsp;&nbsp;</i
          >
        </a>
        <a (click)="goToLink(userProfileDetails.instagram)">
          <i
            *ngIf="userProfileDetails.instagram != ''"
            class="fa fa-instagram"
            aria-hidden="true"
            >&nbsp;&nbsp;</i
          >
        </a>
      </div>
    </div>
    <div class="profile-edit-info">
      <form #profileForm="ngForm">
        <div class="pe-item">
          <h6>First Name</h6>
          <p>{{ selectedUserData.first_name }}</p>
        </div>
        <div class="pe-item mrt-10">
          <h6>Last Name</h6>
          <p>{{ selectedUserData.last_name }}</p>
        </div>
        <div class="pe-item">
          <h6>UserName</h6>
          <p>{{ selectedUserData.user_name }}</p>
        </div>
        <ng-container *ngIf="selectedUserData?.user_type === 'CELEBRITY'">
          <ng-container *ngIf="selectedUserData.rating === 0">
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
          </ng-container>
          <ng-container *ngIf="selectedUserData.rating === 1">
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
          </ng-container>
          <ng-container *ngIf="selectedUserData.rating === 2">
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
          </ng-container>
          <ng-container *ngIf="selectedUserData.rating === 3">
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
          </ng-container>
          <ng-container *ngIf="selectedUserData.rating === 4">
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star-o" data-jest="empty star"></span>
          </ng-container>
          <ng-container *ngIf="selectedUserData.rating === 5">
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
            <span class="fa fa-star checked" data-jest="empty star"></span>
          </ng-container>
        </ng-container>

        <!-- <div class="pe-item mrt-10">
                    <h6>Email Address</h6>
                    <p>{{selectedUserData.email}}</p>
                </div> -->
        <div class="pe-item mrt-10">
          <h6>Country</h6>
          <p>{{ selectedUserData.country || "N/A" }}</p>
        </div>
        <div class="pe-item mrt-10">
          <h6>City</h6>
          <p>{{ selectedUserData.city || "N/A" }}</p>
        </div>

        <div
          class="pe-item mrt-10"
          *ngIf="selectedUserData?.user_type === 'CELEBRITY'"
        >
          <h6>Profession</h6>
          <span *ngFor="let prof of selectedUserData.profession"
            >{{ prof.name || "N/A" }} - {{ selectedUserData.specialization }}
          </span>
        </div>
        <div
          class="pe-item mrt-10"
          *ngIf="selectedUserData?.user_type === 'CELEBRITY'"
        >
          <h6>Description</h6>
          <p>{{ selectedUserData.description || "N/A" }}</p>
        </div>

        <div
          class="pe-item mrt-10"
          *ngIf="selectedUserData?.user_type === 'CELEBRITY'"
        >
          <h6>Chat Pricing ($)</h6>
          <p>{{ selectedUserData.chat_pricing || 0 }} /min</p>
        </div>
        <div
          class="pe-item mrt-10"
          *ngIf="selectedUserData?.user_type === 'CELEBRITY'"
        >
          <h6>Call Pricing ($)</h6>
          <p>{{ selectedUserData.call_pricing || 0 }}/min</p>
        </div>

        <div
          class="pe-item mrt-10"
          *ngIf="selectedUserData?.user_type === 'CELEBRITY'"
        >
          <h6>Available Slots</h6>
          <p *ngFor="let slots of selectedUserData.timeslot">
            {{ slots.slotDate ? slots.slotDate : "" }} {{ slots.start_time }} to
            {{ slots.end_time }}
          </p>
        </div>

        <div
          class="buttons-group mrt-10"
          *ngIf="selectedUserData?.user_type === 'CELEBRITY'"
        >
          <!-- <a href="javascript:void(0);" [routerLink]="['/schedule']" 
                (click)="reload_component('/schedule')" class="btn" mat-button><i
                        class="block-icon"></i> Schedule Call/Chat</a> -->
          <a
            href="javascript:void(0);"
            (click)="schedule_call(userProfileDetails._id)"
            class="btn"
            mat-button
            ><i class="block-icon"></i> Schedule Call/Chat</a
          >
        </div>

        <!-- <div class="buttons-group mrt-10">
                    <a *ngIf="!isReported" href="javascript:void(0);" (click)="reportProfile()" class="btn"
                        mat-button><i class="block-icon"></i> Report Profile</a>
                    <a *ngIf="isReported" href="javascript:void(0);" (click)="removeProfileReport()" class="btn"
                        mat-button><i class="block-icon"></i> Reported</a>
                </div> -->
      </form>
    </div>
  </div>
</ng-template>

<!-- end of popup for user profile-->
