import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
//import { CalendarComponent } from './modules/calendar/calendar.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'Transactionhistory',
    loadChildren: () =>
      import('./modules/transactionhistory/transactionhistory.module').then(
        (m) => m.TransactionhistoryModule
      ),
  },
  {
    path: 'user-profile/:id',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then((m) => m.RegisterModule),
  },
  // { path: 'friend-list', loadChildren: () => import('./friend-list/friend-list.module').then(m => m.FriendListModule) },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'language',
    loadChildren: () =>
      import('./modules/language/language.module').then(
        (m) => m.LanguageModule
      ),
  },
  {
    path: 'setting',
    loadChildren: () =>
      import('./modules/setting/setting.module').then((m) => m.SettingModule),
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('./modules/schedule/schedule.module').then(
        (m) => m.ScheduleModule
      ),
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('./policies/policies.module').then((m) => m.PoliciesModule),
  },
  {
    path: 'msg-request',
    loadChildren: () =>
      import('./modules/msgReuest/msgRequest.module').then(
        (m) => m.MsgRequestModule
      ),
  },
  //{ path: 'calendar', component: CalendarComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
