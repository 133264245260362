<div class="video-call-wrap">
  <div *ngIf="timeToEnd" class="cdc-call-end">Call ends in {{ timeToEnd }}</div>
  <div class="row video-group">
    <div id="remote-view" class="remote_view_opt">
      <p id="remote-player-name" class="player-name2"></p>
      <div id="remote-player" class="player"></div>
    </div>

    <div class="remote_player_sec">
      <p id="local-player-name" class="player-name"></p>
      <div id="local-player" class="player"></div>
    </div>
  </div>
  <div class="video-call-bottom">
    <div *ngIf="loginInfo.user_type === 'REGULAR'">
      <button
        class="cmn-btn"
        (click)="muteMic()"
        [ngClass]="microphoneToggle ? 'mute-icon' : 'unmute-icon'"
      ></button>
      <button class="cmn-btn call-end-icon" (click)="callEnd()"></button>
      <button
        class="cmn-btn"
        (click)="muteVideo()"
        [ngClass]="videoToggle ? 'video-off-icon' : 'video-icon'"
      ></button>
      <button
        style="margin-left: 7px"
        class="cmn-btn tips-icon"
        (click)="opentips()"
      ></button>
    </div>

    <div *ngIf="loginInfo.user_type === 'CELEBRITY'">
      <button
        class="cmn-btn"
        (click)="muteMic()"
        [ngClass]="microphoneToggle ? 'mute-icon' : 'unmute-icon'"
      ></button>
      <button class="cmn-btn call-end-icon" (click)="callEnd()"></button>
      <button
        class="cmn-btn"
        (click)="muteVideo()"
        [ngClass]="videoToggle ? 'video-off-icon' : 'video-icon'"
      ></button>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <!-- <div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Profile update</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div> -->
  <!-- <div class="modal-body">
   <h1>Hello World</h1>
   <p>Hellodfvdvdfvfd</p>
	</div> -->
  <div
    style="
      width: 400px;
      margin: auto;
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    "
  >
    <div style="text-align: center; font-size: 20px; margin-bottom: 15px">
      Send Tips
    </div>
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
    <div
      class="modal-body"
      style="padding: 10px; border-bottom: 1px solid #ccc"
    >
      <div class="modal-bodyTips">
        <div class="subtips" style="font-weight: bold; color: #333">
          <span>ADD Tips</span>
        </div>
        <div
          class="subtips_opt_view"
          style="display: flex; align-items: center; margin-top: 10px"
        >
          <div class="subtips_num" style="margin-right: 10px">
            <ng-container>
              <span style="color: #007bff">#</span>
            </ng-container>
          </div>
          <input
            type="text"
            name="tips_values"
            [(ngModel)]="tips_values"
            style="padding: 5px; border: 1px solid #ccc; border-radius: 4px"
          />
        </div>
      </div>
    </div>

    <div style="text-align: right; padding-top: 10px">
      <button
        style="
          background-color: #007bff;
          color: #fff;
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        "
        (click)="sendtips()"
      >
        Give Tips
      </button>
    </div>
  </div>

  <!-- <div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
	</div> -->
</ng-template>
