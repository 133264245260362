<div class="chat-menu-bar">

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <!-- <p style="font-size: 20px; color: white">Processing... Please wait..</p> -->
    </ngx-spinner>


    <div class="chat-logo-box">
        <a [routerLink]="['/home']" title="Image Slider" >
            <img src="assets/imgs/logo-icon.svg">
        </a>

             <!-- <p *ngFor="let info of newdata">
              {{info.type}} 
      
            </p> -->

    </div>
    <ul class="chat-menu-listed">
        <div class="language-btn">
            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon class="language-icon"></mat-icon>
                {{siteLanguage}}
                <mat-icon class="down-icon">arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <a *ngFor="let lang of languageList" mat-menu-item (click)="setLanguage(lang.short_name)" >{{lang.short_name}}</a>
            </mat-menu>
        </div>







        <li>
            <a  (click)="toggle()" >
                <!-- <img src="assets/imgs/notification-bell.svg" width="20" height="20" alt="" fill="currentColor">
                 -->
                 <i class="fa fa-info-circle notification_info" style="color: whitesmoke;"></i>
                 <!-- <span class="icon-button__badge">{{count}}</span> -->
                 <!-- <p class="icon-button__badge">{{count}}</p> -->
            </a>
           
            <ng-container *ngIf="show" class="slider_popupinfo">
                <div class="slider_add">
                   
                    <div class="slider_wrap">
                       
                        <div class="close_icon" (click)="close_toggle()">
                            x
                            
                        </div>
                        <div class="slider_item11 slider_sidebar" >
                            <owl-carousel-o [options]="carouselOptions">
                                <ng-container *ngFor="let slide of imageList">
                                  <ng-template carouselSlide [width]="600" >
                                    <!-- <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title"> -->
                                    <img src="{{getProfileImage(slide.path)}}">
                                  </ng-template>
                                </ng-container>
                                  
                                </owl-carousel-o>
                            <!-- <owl-carousel [options]="carouselOptions">
                                <ng-template carouselSlide *ngFor="let image of images">
                                  <img [src]="image" alt="Slide Image" />
                                </ng-template>
                              </owl-carousel>   -->
                            <!-- <img src="../../../../assets/imgs/slider1.jpg" alt=""> -->
                            <!-- <owl-carousel-o [options]="customOptions">

                                    <ng-template carouselSlide >
                                        <img src="../../../../assets/imgs/slider1.jpg" alt="" >
                                    </ng-template>  
                                    <ng-template  carouselSlide ><img src="../../../../assets/imgs/slider1.jpg" alt="" ></ng-template>  
                                    <ng-template carouselSlide ><img src="../../../../assets/imgs/slider1.jpg" alt="" ></ng-template> 
                                    <ng-template carouselSlide ><img src="../../../../assets/imgs/slider1.jpg" alt="" > </ng-template>  

                                    <ng-template carouselSlide ><img src="../../../../assets/imgs/slider1.jpg" alt="" ></ng-template>  

                                    <ng-template carouselSlide ><img src="../../../../assets/imgs/slider1.jpg" alt="" ></ng-template>  


                              
                              </owl-carousel-o> -->
                             
                             
                        </div>

                       
    
                    </div>
                    
                </div>
            </ng-container>
        </li>

        <li>
            <a (click)="openPopup()" routerLinkActive="active" title="Notification">
                <!-- <img src="assets/imgs/notification-bell.svg" width="20" height="20" alt="" fill="currentColor">
                 -->
                 <i class="menu-icon-6"></i>
                 <span class="icon-button__badge">{{count}}</span>
                 <!-- <p class="icon-button__badge">{{count}}</p> -->
            </a>
        </li>



        <li >
            <a [routerLink]="['/Transactionhistory']" routerLinkActive="active" title="Transactionhistory"
                (click)="reload_component('/Transactionhistory')">
                <i class="menu-icon-7">

                   
                </i>
                
            </a>
        </li>

     
        <li>
            <a  [routerLink]="['/user/profile']" routerLinkActive="active" 
                (click)="reload_component('/user/profile')" title="Profile">
                <i class="menu-icon-3"></i>
                <!-- (mouseenter)="showTooltip = true"
                (mouseleave)="showTooltip = false" -->
                <!-- <span *ngIf="showTooltip" class="custom-tooltip">Profile</span> -->
            </a>
        </li>
        <!-- <li>
            <a [routerLink]="['/msg-request']" routerLinkActive="active" title="Message Request"
                (click)="reload_component('/msg-request')">
                <img src="assets/imgs/request_new.svg" width="20" height="20" alt="" fill="currentColor"> -->
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-calendar3 svg-common-class" viewBox="0 0 16 16">
                    <path
                        d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path
                        d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg> -->
            <!-- </a>
        </li> -->
        <li>
            <a [routerLink]="['/schedule']" routerLinkActive="active" title="Schedule"
                (click)="reload_component('/schedule')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-calendar3 svg-common-class" viewBox="0 0 16 16">
                    <path
                        d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path
                        d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
            </a>
        </li>
        <li>
            <a  [routerLink]="['/user/friend-list']" routerLinkActive="active" title="User-Friend List"
                (click)="reload_component('/user/friend-list')">
                <i class="menu-icon-1"></i>
            </a>
        </li>

        <!-- <li>
            <a  [routerLink]="['/user/call-check']" routerLinkActive="active" title="Call"
                (click)="reload_component('/user/call-check')">
                <i class="fa fa-phone"></i>
            </a>
        </li> -->

        <li>
            <a  [routerLink]="['/user/chat']" routerLinkActive="active" title="Chat"
                (click)="reload_component('/user/chat')">
                <i class="menu-icon-2"></i>
            </a>
        </li>
        
        <li>
            <a  [routerLink]="['/language']" routerLinkActive="active" title="Language"
                (click)="reload_component('/language')">
                <i class="menu-icon-4"></i>
            </a>
        </li>
        <li>
            <a  [routerLink]="['/setting']" routerLinkActive="active" title="Settings"
                (click)="reload_component('/setting')">
                <i class="menu-icon-5">

                   
                </i>
                
            </a>


           

        </li>

    
        
        
    </ul>
    <div class="logout-menu" title="logout">
        <a (click)="logout()">
            <i class="menu-icon-6"></i>
        </a>

     
    </div>




  <!-- modal show for notification lists    -->
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h4 class="modal-title">{{type}}</h4>
      </div> -->
      <div 
      style="position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      height: 300px;
      overflow: auto;" >
        
        <div >

            <table class="notification-table">
          
                <tbody>
                    
                    <tr class="notification-lists"  *ngFor="let user of countryList">
                        <td> 
                         <img [src]=" (user.sender_profile != '')? user.sender_profile : 'assets/imgs/profile-img.png' " width="40px" height="40px" style="object-fit: cover;
                         border-radius: 50%;">
                        </td>
                     
                        <td>
                            <p style="color:blue;">{{user.sender_name}}</p>
                            <p>{{user.msg}}</p>
                        </td>
                       
                        <td style="color:rgb(170, 170, 231); padding-bottom: 2px;">{{user.createdAt | date:'EEEE,MM/dd/yyyy'}}</td>
                        
    
                    </tr>
           
                </tbody>
            </table>


        </div>
     

  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" 
                (click)="closePopup()">
          {{'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>


</div>
