<!-- <div class="container-fluid">
  <div class="row p-2">
    <button mat-raised-button color="primary" class="m-2" [disabled]="isCallStarted$ | async" >Start Call</button>
    
    <button mat-raised-button color="primary" class="m-2"  (click)="endCall()">End Call</button>
  </div>
  <div class="row">
    <div class="col text-center">
      <div id="video-wrapper">
        <video #remoteVideo id="remote-video" poster="./assets/video-placeholder.jpg" autoplay playsinline></video>
        <video #localVideo id="local-video" autoplay playsinline [muted]=true></video>
      </div>
    </div>
  </div>
</div> -->
<!-- <button mat-raised-button class="m-2" (click)="answerCall()" [disabled]="isCallStarted$ | async" >Join Call</button> -->

<div class="video-call-wrap">
    <div class="video-call-user">
        <img src="assets/imgs/video-call-user.png">
    </div>
    <video autoplay playsinline src="assets/video/movie.mp4" ></video>
    <div class="video-call-bottom">
        <div class="">
            <button class="cmn-btn mute-icon"></button>
            <button class="cmn-btn call-end-icon"></button>
            <button class="cmn-btn video-icon"></button>
        </div>
        <button class="cmn-btn full-screen-icon"></button>
    </div>
</div>


