<header class="header-wrapper mb-auto">
  <div class="container d-flex justify-content-between align-items-center">
      <h3 class="navbar-brand">
          <a href="javascript:void(0);" [routerLink]="['/home']">
              <img class="logo-desktop" src="assets/imgs/logo.svg">
              <img class="logo-mobile" src="assets/imgs/logo-icon.svg">
          </a>
      </h3>
      <div class="navbar-right-wrap">
          <div class="language-btn">
              <button mat-button [matMenuTriggerFor]="menu">
                  <mat-icon class="language-icon"></mat-icon>
                  {{siteLanguage}}
                  <mat-icon class="down-icon">arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <a *ngFor="let lang of languageList" mat-menu-item (click)="setLanguage(lang.short_name)" >{{lang.short_name}}</a>
              </mat-menu>
          </div>
      </div>
  </div>
</header>