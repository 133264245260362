import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CallService } from 'src/app/services/call/call.service';

@Component({
  selector: 'app-call-section',
  templateUrl: './call-section.component.html',
  styleUrls: ['./call-section.component.scss']
})
export class CallSectionComponent implements OnInit {
  public isCallStarted$: Observable<boolean>;
  private peerId: string;

  @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLVideoElement>;

  constructor(private __call:CallService) { }

  ngOnInit(): void {
    this.__call.localStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.localVideo.nativeElement.srcObject = stream)
    this.__call.remoteStream$
      .pipe(filter(res => !!res))
      .subscribe(stream => this.remoteVideo.nativeElement.srcObject = stream)
  }
  
  ngOnDestroy(): void {
    this.__call.destroyPeer();
  }

  public endCall() {
    this.__call.closeMediaCall();
  }

  // answerCall(){
  //   this.__call.enableCallAnswer()
  // }

}
