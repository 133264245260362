import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {


  apiUrl: string;
  local_data: any;
  local_token: any;
  

  constructor(private __http: HttpClient) { 
    this.apiUrl = environment.api_url;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }


  getnotifications(): Observable<any> {
    return this.__http.get(`${this.apiUrl}/schedule/notification`,{
      headers:{Authorization:this.local_token}
    });
  }
  getcounter(): Observable<any> {
    return this.__http.get(`${this.apiUrl}/schedule/getNotificationCount`,{
      headers:{Authorization:this.local_token}
    });
  }

}
