


<h3 style="text-align: center;font-size:15px;color:black">Select Image Type </h3>

<div *ngIf="!typecheck">
<input style="margin-left: 20px" type="radio" id="free" [(ngModel)]="type" name="image_type" value="0" (change)="getvalue($event);fileInput.click()">
<label style="color:black;
margin-top: 46px;">Free</label>
<input  style="margin-left:200px;"  [(ngModel)]="type" type="radio" id="paid" name="image_type" value="1" (change)="getvalue($event)">
<label style="color:black;
margin-top: 46px;">Paid</label>
</div>



  <div >
     <input  id="filech" style="display: none" type="file" (change)="onFileChanged($event)" #fileInput multiple >
    
     <button type="button" hidden class="btn-cmw" (click)="fileInput.click()" ><i class="photo-icon"></i></button>
  </div>

  <div *ngIf="typecheck && type == 1">
    <h2>Price</h2>
    <form (ngSubmit)="getprice()">
    <input [(ngModel)]="price" type="text" placeholder="Enter your price" name="price" style="margin-left:40px;margin-top: 20px;" required>
    <button type="submit" (click)="fileInput.click()" [disabled]="!price">submit</button>
    </form>
  </div>



