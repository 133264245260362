import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-before-login-header',
  templateUrl: './before-login-header.component.html',
  styleUrls: ['./before-login-header.component.scss']
})
export class BeforeLoginHeaderComponent implements OnInit {
  siteLanguage: string = 'EN';
  siteLocale: string;
  languageList

  constructor(public translate:TranslateService,private __language:LanguageService) { }

  ngOnInit(): void {
    this.listLanguages()

  }
  setLanguage(lang){
    this.translate.use(lang)
    this.siteLanguage = lang

  }

  listLanguages(){
    this.__language.listLanguages()
                    .subscribe((response)=>{
                      if(response.success == true){
                        ////console.log(response.data)
                        this.languageList = response.data.docs
                      }
                    },
                    (err)=>{
                      ////console.log(err)
                    })
  }

}
