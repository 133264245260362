import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private __route:Router){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // if(localStorage.getItem('portal_login_token')){
      //   return true
      // }
      // this.__route.navigate(['/login'])
      // return false;
      const isLoggedIn = !!localStorage.getItem('portal_login_token');
    
      if (isLoggedIn && state.url === '/login') {      
        return false;
      } 
      if (!isLoggedIn && state.url !== '/login') {
       
        return false;
      }
  
      return true;
  }
  
}
