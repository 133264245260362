<div class="call-toaster">
  <div class="caller-info">
    <div class="caller-avatar">
      <img src="{{__calling.userData.profile_image || 'assets/imgs/profile-img.png'}}" alt="Caller's Avatar">
    </div>
    <div class="caller-details">
      <span class="caller-name">{{ title }}</span>
      <span class="call-status">Incoming Call</span>
    </div>
  </div>
  <div class="call-actions">
    <button class="accept-call" (click)="answerCall()">
      <i class="call-icon"></i>
    </button>
    <button class="reject-call" (click)="rejectCall()">
      <i class="call-icon"></i>
    </button>
  </div>
</div>
