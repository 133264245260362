<div>
  <app-caller-toster></app-caller-toster>
  <router-outlet></router-outlet>
  <audio
    preload="auto"
    id="ringtone"
    src="assets/ringing/swinging-617.ogg"
    loop
  ></audio>
  <!-- <app-whatsapp></app-whatsapp> -->
</div>
