import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  setDefaultLang(arg0: string) {
    throw new Error('Method not implemented.');
  }
  apiUrl: string;
  local_data: any;
  local_token: any;

  constructor(private __http: HttpClient) {
    this.apiUrl = environment.api_url;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }
  listLanguages(): Observable<any> {
    return this.__http.get(`${this.apiUrl}/language`,{
      params:{
        is_blocked:'0'
      }
    });
  }

  polices(): Observable<any>{
    return this.__http.get(`${this.apiUrl}/cms?limit=10&page=1&slug=privacy-policy`,{
     
    });
  }

  termsCondition(): Observable<any>{
    return this.__http.get(`${this.apiUrl}/cms`,{
     
    });
  }


}
