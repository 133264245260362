import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from './modules/layout/layout.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap'; // For NgbModal
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LanguageTranslateModule } from './modules/language-translate/language-translate.module';
import { ToastrModule } from 'ngx-toastr';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { AsyncPipe, DatePipe } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MessagingService } from './services/messaging/messaging-service';
import { CallerWrapperComponent } from './caller-wrapper/caller-wrapper.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
//import { CalendarComponent } from './modules/calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import daygridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
// import {NgxSliderModule} from '@angular-slider/ngx-slider';
// import { CarouselModule } from '@coreui/angular';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { NgxMaterialRatingModule } from 'ngx-material-rating';
// import { TransactionhistoryComponent } from '.modules/transactionhistory/transactionhistory.component';

// import { UserProfileComponent } from './user-profile/user-profile.component';

//import { PopupModule } from 'ng2-opd-popup';

const config: SocketIoConfig = {
  url: environment.socket_endpoint,
  options: {},
};
// FullCalendarModule.registerPlugins([
//   // register FullCalendar plugins
//   daygridPlugin,
//   interactionPlugin,
//   timeGridPlugin,
// ]);
@NgModule({
  declarations: [
    AppComponent,
    CallerWrapperComponent,
    WhatsappComponent,
    //CalendarComponent,
    // TransactionhistoryComponent,
    // UserProfileComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LayoutModule,
    CommonModule,
    NgbModalModule,
    NgbDatepickerModule,
    CarouselModule,
    FullCalendarModule,
    HttpClientModule,
    LanguageTranslateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxStarRatingModule,
    ToastrModule.forRoot({
      timeOut: 1800,
      progressBar: true,
      // preventDuplicates: true
    }),
    SocketIoModule.forRoot(config),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    // NgxSliderModule,
    AngularFireModule.initializeApp(environment.firebase),
    // NgxMaterialRatingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [DatePipe, MessagingService, AsyncPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
