import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CallService } from '../call/call.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  apiUrl: string;
  local_data: any;
  local_token: any;

  constructor(private __http: HttpClient) {
    this.apiUrl = environment.api_url;
    this.local_data = JSON.parse(localStorage.getItem('portal_login_data'));
    this.local_token = localStorage.getItem('portal_login_token');
  }

  contactList(): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/chat/my-contacts`, {
      headers: { Authorization: this.local_token }
    });
  }
  giveTips(data):Observable<any> {
    return this.__http.post(`${this.apiUrl}/user/givetips`, data,{
      headers: { Authorization: this.local_token }
    });
  }
  createChat(chatData): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/chat/`, chatData, {
      headers: { Authorization: this.local_token }
    });
  }
  getChatDetails(userId): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/chat/details/${userId}`, {
      headers: { Authorization: this.local_token }
    });
  }
  getChatDetailsByThread(threadId): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/chat/details-by-thread-id/${threadId}`, {
      headers: { Authorization: this.local_token }
    });
  }

  getRecentChats(search_key = ''): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/chat/recent-chat-list`, {
      headers: { Authorization: this.local_token },
      params: {
        search_key
      }
    });
  }

  getRecentChatUserList(search_key = '', is_blocked = '0'): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.get(`${this.apiUrl}/chat/recent-chat-user-list`, {
      headers: { Authorization: this.local_token },
      params: {
        search_key,
        is_blocked
      }
    });
  }

  saveCall(callData): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/chat/save-call`, callData, {
      headers: { Authorization: this.local_token }
    });
  }
  getCelebrityUserList( page:any,search_key:any=''): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    let is_blocked = '0';
    return this.__http.get(`${this.apiUrl}/user/celebrities`, {
      headers: { Authorization: this.local_token },
      params: {
        page,
        is_blocked,
        search_key
      }
    });
  }
  makeChatPayment(callData): Observable<any> {
    this.local_token = localStorage.getItem('portal_login_token');
    return this.__http.post(`${this.apiUrl}/chat/payment`, callData, {
      headers: { Authorization: this.local_token }
    });
  }
}
