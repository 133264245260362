import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { CallService } from '../services/call/call.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-caller-toster',
  templateUrl: './caller-toster.component.html',
  styleUrls: ['./caller-toster.component.scss'],
  // animations: [
  //   trigger('flyInOut', [
  //     state('inactive', style({
  //       opacity: 0,
  //     })),
  //     transition('inactive => active', animate('400ms ease-out', keyframes([
  //       style({
  //         transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
  //         opacity: 0,
  //       }),
  //       style({
  //         transform: 'skewX(20deg)',
  //         opacity: 1,
  //       }),
  //       style({
  //         transform: 'skewX(-5deg)',
  //         opacity: 1,
  //       }),
  //       style({
  //         transform: 'none',
  //         opacity: 1,
  //       }),
  //     ]))),
  //     transition('active => removed', animate('400ms ease-out', keyframes([
  //       style({
  //         opacity: 1,
  //       }),
  //       style({
  //         transform: 'translate3d(100%, 0, 0) skewX(30deg)',
  //         opacity: 0,
  //       }),
  //     ]))),
  //   ]),
  // ],
  preserveWhitespaces: false,
})
export class CallerTosterComponent extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    public __calling: CallService,
    public __route: Router
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }

  answerCall() {
    this.__calling.acceptedCall();
    this.redirectTo('/user/friend-list');
  }

  redirectTo(uri: string) {
    this.__route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.__route.navigate([uri]);
    });
  }

  rejectCall() {
    this.__calling.rejectCall();
  }
}
