import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import qs from 'querystring'


@Injectable({
  providedIn: 'root',
})
export class GoogleTranslationService {
  url = 'https://google-translate1.p.rapidapi.com/language/translate/v2';
  // url = 'https://translation.googleapis.com/language/translate/v2?key=';
  // key = 'AIzaSyDvyaByrBmicYWUJLLMcdvcNofzBzWpWbw';

  constructor(private http: HttpClient) {}
  translate(obj:any) {
    return this.http.post(this.url,qs.stringify(obj),{
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'accept-encoding': 'application/gzip',
        'x-rapidapi-key': '6ebf04ebd1msh3bb8d41615fd5c7p16a037jsn33ad6c25b076',
        'x-rapidapi-host': 'google-translate1.p.rapidapi.com'
      },
      
    });
  }
}

export interface GoogleObj {
  q: string[];
  target: string;
}
