<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="ratiingTips_Wrapper" >

    
   
    <div class="tips_wrappercls ">
        <div class="cmn-shadow-head">
            <div class="">
                <span mat-dialog-close class="back-arrow">
                </span>

            </div>
            <span>
                Give Tips To {{modalData.first_name}} {{modalData.last_name}}
            </span><br>
           
        </div>
        <!-- <div class="profile-img-block" [style.background-image]="userProfileImage">
            <i class="camera-icon" >
            </i>
        </div> -->
        <div class="modal-body">
            <div class="modal-bodyTips">
                <div class="subtips"><span>ADD Tips</span></div>
                <div class="subtips_opt_view">
                    <div class="subtips_num">
                        <ng-container *ngFor="let i of tips;let idx=index">
                            <span  [ngClass]="{'selectcls':i.isSelect }" (click)="onClick(i,idx)">
                                {{i.data}}
                            </span>

                        </ng-container>
                    </div>
                  
                   
                </div>
                <br>
                <input type='number' (keyup)="inputTips($event)" [value]="tipsdata" [(ngModel)]="tips_value">
               
            </div>

        </div>
       
    </div><br>
    <div class="actionBtncls submit_rating">

        
        <!-- <button type="acceptbutton"  mat-raised-button (click)="onSubmit()">Submit Rateing</button>&nbsp; -->
        <button type="acceptbutton"  mat-raised-button (click)="tipsGiven()">Send Tips</button>&nbsp;
        <button type="revisebutton" mat-raised-button color="primary" mat-dialog-close (click)="refrshScreen()">Cancel</button>
    </div>

</div>