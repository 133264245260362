import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ChatSectionComponent, DialogRaingTipsDialog, Openpopup,SendTipsDialog,DialogRaingTipsDialogs } from './chat-section/chat-section.component';
// import { ChatSectionComponent } from './chat-section-new/chat-section.component';
import { BeforeLoginHeaderComponent } from './before-login-header/before-login-header.component';
import { SharedModule } from '../shared/shared.module';
import { CallSectionComponent } from './call-section/call-section.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
// import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LanguageTranslateModule } from '../language-translate/language-translate.module';
// import { RatingModule } from 'ngx-rating';




@NgModule({
  declarations: [LeftSidebarComponent, Openpopup, ChatSectionComponent, BeforeLoginHeaderComponent, CallSectionComponent,DialogRaingTipsDialog],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    // SlickCarouselModule,
    RouterModule,
    CarouselModule,
    NgxStarRatingModule,
    // RatingModule,
    PickerModule,
    LanguageTranslateModule,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  entryComponents:[DialogRaingTipsDialog,Openpopup,SendTipsDialog,DialogRaingTipsDialogs],
  exports:[LeftSidebarComponent,ChatSectionComponent,BeforeLoginHeaderComponent,CallSectionComponent,]
})
export class LayoutModule { }
