<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="ratiingTips_Wrapper" >
    <div class="rateing_wrappercls ">
        <div class="cmn-shadow-head">
            <div class="">
                <span mat-dialog-close class="back-arrow">
                </span>

            </div>
            <span>
                Give Rating To {{modalData.first_name}} {{modalData.last_name}}
            </span>
        </div>
        <div class="profile-img-block" [style.background-image]="userProfileImage">
            <i class="camera-icon" >
            </i>
        </div>

        <div class="modal-body">
            <!-- <div class="stars">
                <ngx-star-rating [(ngModel)]="rating" [ngModelOptions]="{standalone: true}" [id]="'rating'">
                </ngx-star-rating>
            </div> -->
            <!-- <ngx-star-rating [(ngModel)]="rating1" [id]="'rating1'" ></ngx-star-rating > -->
                <div class="rate">
                    <input type="radio" id="star5" name="rate" (change)="onItemChange($event.target.value)"  value="5" />
                    <label for="star5" title="text">5 stars</label>
                    <input type="radio" id="star4" name="rate" (change)="onItemChange($event.target.value)" value="4" />
                    <label for="star4" title="text" >4 stars</label>
                    <input type="radio" id="star3" name="rate" (change)="onItemChange($event.target.value)"  value="3" />
                    <label for="star3" title="text">3 stars</label>
                    <input type="radio" id="star2" name="rate" (change)="onItemChange($event.target.value)" value="2" />
                    <label for="star2" title="text">2 stars</label>
                    <input type="radio" id="star1" name="rate" (change)="onItemChange($event.target.value)" value="1" />
                    <label for="star1" title="text">1 star</label>
                  </div>
           
        </div>
       
    </div><br>
    
    <div class="actionBtncls submit_rating">

        
        <button type="acceptbutton"  mat-raised-button (click)="onSubmit()">Submit Rating</button>&nbsp;
        
        <button type="revisebutton" mat-raised-button color="primary" mat-dialog-close (click)="refrshScreen()">Cancel</button>
    </div>

</div>