import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
  Inject,
  TemplateRef,
} from '@angular/core';
declare let RTCPeerConnection: any;
import moment from 'moment';
import { UserService } from 'src/app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatService } from 'src/app/services/chat/chat.service';

import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { CallService } from 'src/app/services/call/call.service';
import { Observable } from 'rxjs';
import { GoogleTranslationService } from 'src/app/services/google-translation/google-translation.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogData } from '../../schedule/list-schedule/list-schedule.component';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ThemePalette } from '@angular/material/core';

//dependencies of agoraRTC
import AgoraRTC from 'agora-rtc-sdk-ng';
import { StreamService } from 'src/app/services/stream/stream.service';
import {
  AgoraClient,
  ClientEvent,
  NgxAgoraService,
  Stream,
  StreamEvent,
} from 'ngx-agora';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { environment } from 'src/environments/environment';

//import { Popup } from 'ng2-opd-popup';
const offerOptions = {
  offerToReceiveAudio: true,
  offerToReceiveVideo: true,
};

@Component({
  selector: 'app-chat-section',
  templateUrl: './chat-section.component.html',
  styleUrls: ['./chat-section.component.scss'],
})

// @Component({
//   selector: 'pop-up',
//   templateUrl: './pop-up.component.html',
//   styleUrls: ['./modal.css']

// })
export class ChatSectionComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('msgTextArea') private msgTextArea: ElementRef;
  @Input('chatUserId') public chatUserId = '';
  @Input('selectedLang') public selectedLang = '';
  @ViewChild('fileInput') myDiv: ElementRef<HTMLElement>;

  @Input()
  public set userDetails(val) {
    //debugger
    if (
      val != '' &&
      Object.keys(val).length > 0 &&
      val.user_type == 'CELEBRITY'
    ) {
      this.userProfileDetailsParent = val;
      this.checkSchedule(val._id);
    }
    if (
      val != '' &&
      Object.keys(val).length > 0 &&
      val.user_type != 'CELEBRITY'
    ) {
      this.userProfileDetailsParent = val;
      this.checkSchedule(val._id);
    }
    // if (val.user_type && val.user_type != 'CELEBRITY') {
    //   this.userProfileDetailsParent = {};
    //   this.disableChat = false;
    //   // //console.log('line 50', val.user_type);

    //   this.enableCall = true;
    // }
    // this.enableCall = true;
  }
  //config details of agora video call
  rtc: any = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  };
  options = {
    // appId: "a9b6c715005642fd875156d06926bb55",
    appId: '011c57e8e5384a5fa4c4489c9b5e46a5',
    // appId: "2d0e4cc86dcd4c0e958b2d06fd58ca2b",
    // token: "007eJxTYFj22OzUpdmlP60+tIv+vnfk1yGxMzvnPX02z2nBpQvsW46dVGBINDUyNzUxNktLMUoxMbGwsEhJNTQ3MjUzNjG0ME0yMqta6J9cG8jI0GE7hZWRAQJBfBaGjEyjUgYGANOwI1s=",
    // channel: ""
    uid: '',
  };
  remoteUsers: any = {};

  public ringingFile = 'assets/ringing/swinging-617.mp3';
  public disableChat: boolean = false;
  public ringingAudio = new Audio(this.ringingFile);
  // @Input('chatThreadId') public chatThreadId = ''
  html: any;
  price: any = 0;
  showFriendList = true;
  showFriendProfile = false;
  userList: any[] = [];
  userProfileDetails: any = {};
  userProfileImage;
  search_key = '';
  active: boolean = false;
  activediv: boolean = true;
  isReported: boolean = false;
  selectedUser: any = '';
  selectedThread: any = '';
  chatDetails: any = {};
  chat_content = '';
  socket;
  recentMsg = '';
  local_token = '';
  onlineUsers: any[] = [];
  selectedFiles: any[] = [];
  sendFile: boolean = true;
  showEmojiPicker: boolean = false;
  images: any[] = [];
  files: any[] = [];
  chatListhow: boolean = false;
  videoCallshow: boolean = false;
  microphoneToggle: boolean = false;
  videoToggle: boolean = false;
  // microphoneToggle: boolean = true;
  // videoToggle: boolean = true;
  receiverDiv: boolean = false;
  public isCallStarted$: Observable<boolean>;
  private peerId: string;
  remoteUserId;
  remoteCallFrom;
  jsondata;
  full_name;
  rating: any;
  userProfileDetailsParent: any = '';
  enableCall: boolean = false;
  placeholder_data: any = '';
  calScheduledata: any = {};
  tracks: any;
  sender_id: any = '';
  isPaid: '';
  ammount: '';
  fileclass: any = [];
  displayStyle = 'none';
  public sample: any;
  public type: any;
  public tempImgType: any;
  public paymentInfo: any;
  incomingCall: boolean = false;
  outgoingCall: boolean = false;
  senderData: any;
  isMice: boolean = true;
  isVideoOn: boolean = true;
  videoCallInfo: any = {
    sender_id: '',
    receiver_id: '',
    type: '',
  };
  subscription: any;
  public ringTime: any = 60;
  public remotePlayer: any = '';
  public callStartTime: any;
  public selectedUserData: any;
  public remoteUserInfo: any;
  public remotePlayerType: string = '';
  agoradetails: any = {
    // appid: "a9b6c715005642fd875156d06926bb55",
    appId: '011c57e8e5384a5fa4c4489c9b5e46a5',
    //  appId: "2d0e4cc86dcd4c0e958b2d06fd58ca2b",
    // token: "007eJxTYFj22OzUpdmlP60+tIv+vnfk1yGxMzvnPX02z2nBpQvsW46dVGBINDUyNzUxNktLMUoxMbGwsEhJNTQ3MjUzNjG0ME0yMqta6J9cG8jI0GE7hZWRAQJBfBaGjEyjUgYGANOwI1s=",
    channel: '',
  };

  @ViewChild('localVideo') localVideo: ElementRef<HTMLVideoElement>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef;
  @ViewChild('fileInput', { static: true }) usernameElement: ElementRef;

  filecg: any = [];

  private localStream: Stream;
  public client: any = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
  /**
   * Channel (meeting room) within the Agora app to join
   */
  channel = new FormControl('123');
  /**
   * Generated user ID that is attached to the local client when joining a meeting room
   */
  uid: number;

  /**
   * All the IDs of other users that have joined the call
   */
  remoteCalls: any[] = [];
  /**
   * Whether the local client has tuned in to the Agora meeting room
   */
  connected = false;
  /**
   * Whether the local client's A/V stream has been published to the remote meeting room
   */
  published = false;
  localTracks = {
    videoTrack: null,
    audioTrack: null,
  };
  noOfWindowOppened: number = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private __user: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private __chat: ChatService,
    private __websocket: WebsocketService,
    private __calling: CallService,
    private google: GoogleTranslationService,
    public signalingService: CallService,
    public dialog: MatDialog,
    public route: Router,
    usernameElement: ElementRef,
    public stream: StreamService,
    private domSanitizer: DomSanitizer,
    public activatedRouter: ActivatedRoute,
    public __route: Router //private popup : Popup,
  ) {
    this.uid = Math.floor(Math.random() * 100);

    this.usernameElement = usernameElement;

    this.spinner.show();
    if (this.__websocket.socket) {
      this.__websocket.socket.emit('login');

      __websocket.socket.on('receiveMessage', (data) => {
        this.getChatDetails();
      });
      __websocket.socket.on('online', (data) => {
        this.onlineUsers = Object.keys(data.onlineUsers);

        // this.onlineUsers = Object.values(data.onlineUsers)
        //////console.log('chat-online----------', this.onlineUsers);
      });
      this.subscription = __calling.callingEvent$.subscribe(async (data) => {
        if (this.__calling.toastrRef) {
          this.__calling.toastrRef.close();
        }
        switch (data.msg.type) {
          case 'request':
            this.sender_id = data.msg.sender_id;
            console.log(data, 'request', data);
            this.videoCallInfo.sender_id = data.msg.sender_id;
            this.videoCallInfo.receiver_id = data.msg.receiver_id;
            this.videoCallInfo.type = 'request';
            this.videoCallshow = false;
            this.chatListhow = false;
            this.incomingCall = true;

            this.outgoingCall = true;

            this.getRemotePlayerdata(data.msg.sender_id);
            this.__calling.ringingToneStart();
            if (this.ringTime != null && this.ringTime >= 0) {
              setTimeout(() => {
                if (this.ringTime != null && this.ringTime == 0) {
                  this.videoCallshow = false;
                  this.chatListhow = true;
                  this.incomingCall = false;

                  this.outgoingCall = false;

                  this.__calling.ringingToneEnd();
                  if (this.ringTime != null) {
                    let message1 = {
                      sender_id: data.msg.receiver_id,
                      receiver_id: data.msg.sender_id,
                      type: 'not-answered',
                    };
                    // //console.log(message1, 'message1..........');

                    this.__websocket.socket.emit('videocall', message1);
                  }
                }
              }, this.ringTime * 1000);
            }
            break;

          case 'answer':
            this.sender_id = data.msg.sender_id;
            let today = new Date();
            let startTime =
              today.getHours() +
              ':' +
              String(today.getMinutes()).padStart(2, '0') +
              ':' +
              today.getSeconds();
            this.callStartTime = startTime;
            // this.__calling.ringingToneEnd()
            console.log('answer', data);
            this.videoCallshow = false;
            this.chatListhow = true;
            this.incomingCall = false;

            this.outgoingCall = false;

            localStorage.setItem('callData', data);

            this.videoCallInfo.sender_id = data.msg.sender_id;
            this.videoCallInfo.receiver_id = data.msg.receiver_id;
            this.videoCallInfo.type = data.msg.type;

            this.__calling.videoCallInfo = { ...this.videoCallInfo };

            // this.join(data.msg.sender_id);

            localStorage.setItem(
              'videoCallInfo',
              JSON.stringify(this.videoCallInfo)
            );
            this.openCallingWindow(data.msg.sender_id);
            break;

          case 'not-answered':
            this.videoCallshow = false;
            this.chatListhow = true;
            this.incomingCall = false;

            this.outgoingCall = false;

            this.__calling.ringingToneEnd();
            this.toastr.warning('User not available');
            break;

          case 'reject':
            //console.log("reject",data.msg)
            this.videoCallshow = false;
            this.chatListhow = true;
            this.incomingCall = false;

            this.outgoingCall = false;

            this.__calling.ringingToneEnd();
            break;

          case 'leave':
            console.log('leave', data.msg);
            this.videoCallshow = false;
            this.chatListhow = true;
            this.incomingCall = false;

            this.outgoingCall = false;

            this.leave();
            // window.location.reload()
            break;

          default:
            break;
        }
      });

      __websocket.socket.on('informCallEnd', (data) => {
        //console.log('EMIT LOGIN informCallEnd');
        this.videoCallshow = false;
        this.__calling.closeMediaCall();
        this.__calling.ringingToneEnd();
        this.getChatDetails();
        this.rateingTips();
      });
      this.__websocket.socket.on('informCallStart', (data) => {
        ////console.log('EMIT LOGIN informCallStart');

        this.__calling.ringingToneStart();
      });
      this.__websocket.socket.on('informCallReceived', (data) => {
        this.__calling.ringingToneEnd();
      });
      this.__websocket.socket.on('informCallNotReceived', (data) => {
        ////console.log('informCallNotReceived');

        this.videoCallshow = false;
        this.__calling.closeMediaCall();
        this.__calling.ringingToneEnd();
      });
    }
  }

  ngOnInit(): void {
    //AGOIRA CLIENT CREATE ON INIT
    this.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

    this.jsondata = JSON.parse(localStorage.getItem('portal_login_data'));
    this.full_name = this.jsondata.first_name + ' ' + this.jsondata.last_name;
    this.scrollToBottom();
    this.__calling.initPeer(
      JSON.parse(localStorage.getItem('portal_login_data'))['_id']
    );
    this.__calling.enableCallAnswer();

    //to show selecteed username in chat secton head
    this.getSelectedUserInfo();
    //debugger
    setInterval(() => {
      //console.log(this.userProfileDetailsParent, 'userProfileDetailsParent');

      if (this.userProfileDetailsParent.user_type == 'CELEBRITY') {
        this.checkSchedule(this.userProfileDetailsParent._id);
      } else {
        this.userProfileDetailsParent = {};
      }
    }, 30000);
    setInterval(() => {
      if (Object.keys(this.calScheduledata).length > 0) {
        //console.log('setIntervELhIT', this.calScheduledata);
        this.callDisconnect(this.calScheduledata);
      }
    }, 5000);

    this.checkCallEvt();

    this.__calling.onCallScreenBlur$.next(false);
  }

  public checkCallEvt() {
    const { channalId } = this.__calling;
    const isCallStarted = this.__calling.isCallStartedBs.value;
    if (channalId && !isCallStarted) {
      const { callData } = this.__calling;
      this.__calling.callingEvent$.next(callData);
      // this.acceptCall(channalId);

      this.incomingCall = false;
      this.outgoingCall = false;
      this.videoCallshow = false;
      this.chatListhow = true;
      this.videoCallInfo.type = 'answer';
      this.__calling.ringingToneEnd();
      this.openCallingWindow(channalId);
    }
  }

  showDiv() {
    this.activediv = false;
    this.active = true;
  }

  //toshow username on top section

  opentips() {
    // const dialogRef = this.dialog.open(SendTipsDialog, {
    //   data: { data: this.userProfileDetails, scheduledata: this.calScheduledata },
    //   panelClass: 'testclss',
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   ////console.log('The dialog was closed');

    // });

    const dialogRef = this.dialog.open(DialogRaingTipsDialog, {
      data: { data: this.remoteUserInfo, scheduledata: this.calScheduledata },
      panelClass: 'testcls',
    });
    dialogRef.afterClosed().subscribe((result) => {
      ////console.log('The dialog was closed');
    });
  }

  getSelectedUserInfo() {
    if (
      localStorage.getItem('chatUserData') != '' &&
      localStorage.getItem('chatUserData') != undefined
    ) {
      this.selectedUserData = JSON.parse(localStorage.getItem('chatUserData'));
      const currentDate = new Date();
      this.selectedUserData.timeslot = this.selectedUserData.timeslot.filter(
        (slot: any) => {
          const slotDate = new Date(slot.slotDate);
          const slotTime = new Date(`1970-01-01T${slot.end_time}`);
          const slotDateTime = new Date(
            slotDate.getFullYear(),
            slotDate.getMonth(),
            slotDate.getDate(),
            slotTime.getHours(),
            slotTime.getMinutes()
          );
          return slotDateTime > currentDate;
        }
      );
      console.log('userInfo', this.selectedUserData);
      console.log(
        'username',
        this.selectedUserData.first_name,
        this.selectedUserData.last_name
      );
    } else {
      this.selectedUserData = {};
    }
  }
  schedule_call(user_id: any) {
    //debugger
    localStorage.setItem('schedule', user_id);
    localStorage.setItem('chatUser', user_id);
    this.route.navigateByUrl('/schedule');
  }
  //get remote user data to show while calling popup appear

  getRemotePlayerdata(userId) {
    this.__user.detailsUser(userId).subscribe(
      (response) => {
        if (response.data.profile_image != '') {
          response.data.profile_image =
            'https://hi2u.app:3020/' + response.data.profile_image;
        } else {
          response.data.profile_image = 'assets/imgs/profile-img.png';
        }
        this.remoteUserInfo = response.data;
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  //agora video call 4 functions

  join = async (id) => {
    localStorage.setItem('channelId', id);
    this.__calling.channalId = id;
    this.agoradetails.channel = id;
    //console.log(this.client);
    await this.client.on('user-published', this.handleUserPublished.bind(this));
    await this.client.on(
      'user-unpublished',
      this.handleUserUnpublished.bind(this)
    );
    this.client.on('user-left', (user, res) => {
      //console.log(user)
      this.leave();
    });
    // [ this.options['uid'], this.localTracks.audioTrack, this.localTracks.videoTrack ] = Promise.all([
    // join the channel

    this.options['uid'] = await this.client.join(
      this.options.appId,
      this.agoradetails.channel,
      null
    );
    //  this.__calling.userUid = this.options.uid
    this.__calling.isUserJoinedBs.next(true);
    // create local tracks, using microphone and camera
    this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack();

    this.__calling.localTracks = { ...this.localTracks };
    // //console.log(this.localTracks.videoTrack);
    //console.log(this.options);

    // play local video track
    this.localTracks.videoTrack.play(document.getElementById('local-player'));
    document.getElementById('local-player-name').innerHTML = '';
    // $("#local-player-name").text(`localVideo(${this.options['uid']})`);

    // publish local tracks to channel
    //console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;', this.client, 'kkkkkkkkkkkkkkkkkkkkkkkkkk');
    setTimeout(() => {
      this.client.publish(Object.values(this.localTracks));
    }, 500);
  };

  async handleUserPublished(user: any, mediaType: any) {
    //console.log("next user ",user);

    const id = user.uid.toString();
    if (this.__calling.userUid === undefined) {
      this.__calling.userUid = id;
    }

    this.remoteUsers[id] = user;
    //console.log(mediaType, '<><<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>', user);
    const { userUid } = this.__calling;
    if (userUid == id) {
      await this.client.subscribe(user, mediaType);
      this.__calling.subscribedStreams.push(id);
    }

    const uid = user.uid;
    //console.log(uid, 'kkkkkkkkkkkkkkkkkkkkkkkkkk');

    //this.client.subscribe(user, mediaType);

    document.getElementById('remote-view').style.display = 'block';
    // subscribe to a remote user
    setTimeout(() => {
      //console.log("subscribe succes!><<<<<<<<<<<<>>>>>>>>>>>s", mediaType);

      if (mediaType === 'video') {
        const player = `
      <div id="player-wrapper-${uid}">
        <p class="player-name">remoteUser(${uid})</p>
        <div id="player-${uid}" class="player"></div>
      </div> `;
        //console.log("checkk    +++", user.videoTrack);
        // document.getElementById("#remote-playerlist").append(player);
        //user.videoTrack.play(`player-${uid}`);
        user.videoTrack.play(document.getElementById('remote-player'));
        document.getElementById('remote-player-name').innerHTML =
          this.remotePlayer;
        if (document.getElementById('remoteUserIc')) {
          document.getElementById('remoteUserIc').style.display = 'none';
        }
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
      }

      this.__calling.remoteUsers = _.cloneDeep(user);
    }, 500);
  }

  muteMic() {
    this.microphoneToggle = !this.microphoneToggle;
    this.isMice = !this.localTracks.audioTrack.enabled;
    this.localTracks.audioTrack.setEnabled(
      !this.localTracks.audioTrack.enabled
    );
  }

  muteVideo() {
    this.videoToggle = !this.videoToggle;
    this.isVideoOn = !this.localTracks.videoTrack.enabled;
    this.localTracks.videoTrack.setEnabled(this.isVideoOn);
  }

  subscribe = async (user: any, mediaType: any) => {
    //console.log('.......///////////////////////////', user.videoTrack);
  };

  handleUserUnpublished(user) {
    //console.log('http://localhost:4200/#/user/friend-list');

    const id = user.uid;
    delete this.remoteUsers[id];
    // $(`#player-wrapper-${id}`).remove();
  }
  // unpublish(): void {
  //   this.client.unpublish(this.localStream, error => console.error(error));
  //   this.published = false;
  // }

  leave = async () => {
    try {
      console.log('leave request');
      for (let trackName in this.localTracks) {
        var track = this.localTracks[trackName];
        if (track) {
          track.stop();
          track.close();
          this.localTracks[trackName] = undefined;
        }
      }

      // remove remote users and player views
      this.remoteUsers = {};
      // $("#remote-playerlist").html("");
      // document.getElementById('remote-player-name').innerHTML = '';
      // document.getElementById('local-player-name').innerHTML = '';

      // leave the channel
      await this.client.leave();

      this.__calling.isUserJoinedBs.next(false);

      //call socket to end caall
      let message1 = {
        sender_id: this.videoCallInfo.sender_id,
        receiver_id: this.videoCallInfo.receiver_id,
        type: 'leave',
      };
      console.log('message1', message1);
      this.__websocket.socket.emit('videocall', message1);
      // window.location.reload()
      let userInfo = JSON.parse(localStorage.getItem('portal_login_data'));
      console.log('userInfo==> ', userInfo);
      // if (userInfo.user_type == 'CELEBRITY') {
      // }

      // setTimeout(() => {
      //   window.location.href = window.location.href;
      // }, 1000);
    } catch (error) {
      console.log(error);
    } finally {
      this.__calling.userUid = undefined;
      localStorage.removeItem('channelId');
      this.__calling.channalId = null;
      // window.location.reload();
      // this.__calling.callingWindow.close()
    }
  };

  // openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef);
  // }
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    const dialogRef = this.dialog.open(templateRef);

    // Subscribe to the route navigation event
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Close the dialog when navigation to /schedule occurs
        if (event.url === '/schedule') {
          dialogRef.close();
        }
      }
    });
  }

  openDialogWithProfile(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: '300px;',
    });
  }

  paymentConfirmDialog(templateRef: TemplateRef<any>, data) {
    this.dialog.open(templateRef);
    let convId = this.getConvId(data.id);
    this.paymentInfo = {
      chatid: this.chatDetails._id,
      conversationid: convId,
      chatfileid: data.id,
      price: data.price,
    };
    //console.log("paymentInfo",this.paymentInfo)
  }

  confirmPayment() {
    // alert("this is payment section")
    //console.log("this is payment section")

    this.__chat.makeChatPayment(this.paymentInfo).subscribe((res: any) => {
      //console.log("resp ",res.message)
      if (res) {
        this.toastr.success(res.message);
      }
      this.paymentInfo = {};
    });
  }

  cancelPayment() {
    this.paymentInfo = {};
  }

  openFileBrowse() {
    this.tempImgType = this.type;
    this.type = '';
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }

  getConvId(chatFileId) {
    for (let i = 0; i < this.chatDetails.conversation.length; i++) {
      for (
        let j = 0;
        j < this.chatDetails.conversation[i].chat_files.length;
        j++
      ) {
        if (this.chatDetails.conversation[i].chat_files[j].id == chatFileId) {
          return this.chatDetails.conversation[i]._id;
          break;
        }
      }
    }
  }

  openoption() {
    const dialogRef = this.dialog.open(Openpopup, {
      data: '',
      width: '400px',
      height: '200px',
      panelClass: 'bg-color',
    });
    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      //this.fileclass.click();
    });
    //this.popup.show();
  }

  openPopUp() {
    const dialogRef = this.dialog.open(Openpopup, {
      data: '',
      width: '400px',
      height: '200px',
      panelClass: 'bg-color',
    });
    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      //this.fileclass.click();
    });
    //this.popup.show();
  }

  // setpopup(){

  //   const dialogRef = this.dialog.open(PopUp, {
  //     data: '',
  //     width:'400px',
  //     height:'200px',
  //     panelClass: 'bg-color',

  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     //console.log('The dialog was closed');

  //     //this.fileclass.click();

  //   });
  //   //this.popup.show();
  // }

  showSuccess(msg) {
    this.toastr.success(msg, 'Success!');
  }

  showError(msg) {
    this.toastr.error(msg, 'Error!');
  }

  resizeTextEditor(e) {
    var el = e.target;
    console.log('----------', this.chat_content.length);
    console.log(this.chat_content === '');
    setTimeout(function () {
      el.style.cssText = 'height:auto; padding:0';
      el.style.cssText = 'height:' + el.scrollHeight + 'px';
    }, 0);
    const keyCode = e.which || e.keyCode;
    if (e.which == 32 && el.selectionStart === 0) {
      // e.preventDefault();
      return false;
    } else if (keyCode === 13 && !e.shiftKey) {
      // Don't generate a new line in textarea just submit the message on enter
      e.preventDefault();

      this.sendMessage();
    }
  }

  ngAfterViewInit() {
    ////console.log('ngAfterViewInit');
    // var textarea = document.querySelector('.cmw-emoji-with-textarea textarea');
    // textarea.addEventListener('keydown', autosize);
    // function autosize() {
    //   //////console.log('autosize called===');
    //   var el = this;
    //   setTimeout(function () {
    //     el.style.cssText = 'height:auto; padding:0';
    //     el.style.cssText = 'height:' + el.scrollHeight + 'px';
    //   }, 0);
    // }
    // $(document).ready(() => {
    //   $('cmw-emoji-with-textarea textarea').bind('keypress', (e) => {
    //   });
    //   $('.cmw-emoji-with-textarea textarea').bind('keydown', (e) => {
    //     //////console.log('keydown fired');
    //     const keyCode = e.which || e.keyCode;
    //     if (keyCode === 13 && !e.shiftKey) {
    //       // Don't generate a new line
    //       e.preventDefault();
    //       this.sendMessage();
    //     }
    //   });
    // });
    /**Call Section */
    // this.__calling.localStream$
    //   .pipe(filter((res) => !!res))
    //   .subscribe(
    //     (stream) => {
    //       ////console.log(this.localVideo, stream);
    //       if (this.localVideo) {
    //         return this.localVideo.nativeElement.srcObject = stream
    //       }
    //       ////console.log(this.__calling.elebelcallflag);
    //       if (this.__calling.elebelcallflag) {
    //         setTimeout(() => {
    //           // ////console.log(stream, "stream-local", this.__calling.elebelcallflag)
    //           return this.localVideo.nativeElement.srcObject = stream;
    //         }, 500);
    //       }
    //     }
    //   );
    // this.__calling.remoteStream$
    //   .pipe(filter((res) => !!res))
    //   .subscribe(
    //     // (stream) => (this.remoteVideo.nativeElement.srcObject = stream)
    //     (stream) => {
    //       ////console.log(this.remoteVideo, "stream-remote", stream)
    //       if (this.remoteVideo) {
    //         return this.remoteVideo.nativeElement.srcObject = stream
    //       }
    //     }
    //   );
  }

  ngDoCheck() {
    // ////console.log('ngDoCheck');

    if (this.__calling.receiverDiv) {
      this.videoCallshow = this.__calling.receiverDiv;
      this.__calling.ringingToneEnd();
    }
    if (!this.incomingCall && !this.videoCallshow) {
      this.chatListhow = true;
    }
    // this.chatListhow = !this.videoCallshow;
    // this.chatListhow = !this.incomingCall;
  }

  ngOnChanges() {
    ////console.log('ngOnChanges');

    this.scrollToBottom();
    this.selectedUser = this.chatUserId;
    ////console.log(this.selectedUser);

    if (this.selectedUser) {
      this.getUserDetails(this.chatUserId);
      this.getChatDetails();
      // this.translateText()
    }
  }

  ngAfterViewChecked() {
    // ////console.log('ngAfterViewChecked');

    // this.scrollToBottom();

    // console.log("<<<<<<<<<<<<<<<",this.chat_content);

    this.sendFile = this.chat_content != '' ? false : true;
    this.cdr.detectChanges();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }
  getUserDetails(userId) {
    this.__user.detailsUser(userId).subscribe(
      (response) => {
        //console.log("All data",response.data);

        this.userProfileDetails = response.data;
        this.getSelectedUserInfo();
        this.userProfileImage =
          this.userProfileDetails.profile_image != ''
            ? `url('${this.userProfileDetails.profile_image}')`
            : "url('assets/imgs/profile-img.png')";
        //console.log("image url",this.userProfileDetails.profile_image);

        if (Object.keys(this.userProfileImage).length > 0) {
          this.spinner.hide();
        }

        //////console.log(response);
      },
      (err) => {
        this.spinner.hide();

        //////console.log(err);
      }
    );
  }
  getChatDetails() {
    this.__chat.getChatDetails(this.selectedUser).subscribe(
      (response) => {
        this.chatDetails = response.data || {};

        this.scrollToBottom();
        // this.chatDetails['conversation'] = this.chatDetails['conversation'].map(x=>{

        //   const googleObj: GoogleObj = {
        //     // q: this.data.title,
        //     q: [x.chat_content],
        //     target: 'es'
        //   };
        //   this.google.translate(googleObj).subscribe(
        //     (res: any) => {
        //     x['chat_content'] = res.data.translations[0].translatedText
        // })
        //   return x
        // })
        this.chatDetails.participants = this.chatDetails.participants
          ? this.chatDetails.participants.filter(
              (x) => x['contact_user'] == true
            )
          : [];
      },
      (err) => {
        //////console.log(err);
      }
    );
  }
  getChatDetailsByThread() {
    this.__chat.getChatDetailsByThread(this.selectedThread).subscribe(
      (response) => {
        this.chatDetails = response.data || {};
        // //////console.log('chatDetailsByThread+++++',this.chatDetails)
        // //////console.log(this.chatDetails.participants)
        this.chatDetails.participants = this.chatDetails.participants
          ? this.chatDetails.participants.filter(
              (x) => x['contact_user'] == true
            )
          : [];
      },
      (err) => {
        //////console.log(err);
      }
    );
  }

  closePopup() {
    this.displayStyle = 'none';
  }

  // sendMessage() {
  //   //////console.log("Send message called-------------",this.onlineUsers[this.onlineUsers.length-1], )
  //   let senderId = JSON.parse(localStorage.getItem('portal_login_data'))
  //   if (this.chat_content == '' && this.selectedFiles.length == 0) {
  //     return;
  //   }
  //   // let receiver_id = (this.selectedUser!='')?this.selectedUser:this.chatDetails.participants['0']?.user_id?._id
  //   let formData = new FormData();
  //   formData.append('chat_content', this.chat_content);
  //   // formData.append('receiver_id',receiver_id)
  //   formData.append('receiver_id', this.selectedUser);

  //   //console.log("image length+++",this.selectedFiles.length);
  //   //console.log("Total Price ++++",(this.selectedFiles.length * this.price))

  //   if (this.selectedFiles.length > 0) {
  //     for (let i = 0; i < this.selectedFiles.length; i++) {
  //       formData.append('chat_files', this.selectedFiles[i]);
  //     }
  //   }
  //   formData.append("is_paid",this.tempImgType)
  //   formData.append("price",this.price)
  //   //console.log('formData +++++ ',formData);

  //   this.__chat.createChat(formData).subscribe(
  //     (response) => {
  //       //console.log("===========>chat create response ",response)

  //       if (response.status == 200) {
  //         //this.setState({ images: [] });
  //         var chatfile = [];
  //         if (response.data.chat_files.length > 0) {
  //           for (let i = 0; i < response.data.chat_files.length; i++) {
  //             let dat = {
  //               file_link:  response.data.chat_files[i].file_link,
  //               file_name: response.data.chat_files[i].file_name,
  //               file_type: response.data.chat_files[i].file_type,
  //               is_paid: response.data.chat_files[i].is_paid,
  //               price: response.data.chat_files[i].price,
  //               id: response.data.chat_files[i].id
  //             }
  //             chatfile.push(dat);
  //           }
  //         }
  //       }

  //       this.__websocket.socket.emit('sendMessage', {
  //         // receiver_id:receiver_id,
  //         sender_id: senderId._id,
  //         sent_by_me: false,
  //         receiver_id: this.selectedUser,
  //         chat_content: this.chat_content,

  //         chat_files: chatfile,

  //         type: response.data.type,
  //         createdAt: response.data.createdAt,

  //         id: response.data.id

  //       });
  //       this.chat_content = '';
  //       this.msgTextArea.nativeElement.setAttribute('style', {
  //         height: this.msgTextArea.nativeElement.scrollHeight + 'px',
  //       });
  //        this.price = 0;
  //       this.tempImgType = "";
  //       this.sendFile = true;
  //       this.selectedFiles = [];
  //       this.images = [];
  //       this.showEmojiPicker = false;
  //       this.getChatDetails()
  //     },
  //     (err) => {
  //       this.showError(err.error.message)
  //       //////console.log(err);
  //     }
  //   );
  // }

  sendMessage() {
    //////console.log("Send message called-------------",this.onlineUsers[this.onlineUsers.length-1], )
    var urlRegex =
      /^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    let senderId = JSON.parse(localStorage.getItem('portal_login_data'));
    if (this.chat_content == '' && this.selectedFiles.length == 0) {
      return;
    } else if (this.chat_content.match(urlRegex)) {
      var replacePattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      var replacedText = this.chat_content.replace(
        replacePattern1,
        '<a href="$1" target="_blank">link</a>'
      );
      // this.html = this.domSanitizer.bypassSecurityTrustHtml(`<b>Test</b> <a href='${this.chat_content}' >My Test</a>`);
      // console.log("link data",this.html);

      // const dom = new DOMParser().parseFromString(`<a href='${this.chat_content}' >My Test</a>`,'text/html')
      // const h1 = dom.querySelector('h1')

      // console.log("link data",h1)

      const htmlStr = '<h1>Hello World!</h1>';

      // make a new parser
      const parser = new DOMParser();

      // convert html string into DOM
      const document = parser.parseFromString(htmlStr, 'text/html');

      console.log('link data', document);
    }

    // let receiver_id = (this.selectedUser!='')?this.selectedUser:this.chatDetails.participants['0']?.user_id?._id
    let formData = new FormData();
    formData.append('chat_content', this.chat_content);
    // formData.append('receiver_id',receiver_id)
    formData.append('receiver_id', this.selectedUser);

    //console.log("image length+++",this.selectedFiles.length);
    //console.log("Total Price ++++",(this.selectedFiles.length * this.price))

    if (this.selectedFiles.length > 0) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append('chat_files', this.selectedFiles[i]);
        formData.append('is_paid', this.selectedFiles[i].is_paid);
        formData.append('price', this.selectedFiles[i].price);
      }
    }
    // formData.append("is_paid",this.tempImgType)
    // formData.append("price",this.price)
    //console.log('formData +++++ ',formData);

    this.__chat.createChat(formData).subscribe(
      (response) => {
        //console.log("===========>chat create response ",response)

        if (response.status == 200) {
          //this.setState({ images: [] });
          var chatfile = [];
          if (response.data.chat_files.length > 0) {
            for (let i = 0; i < response.data.chat_files.length; i++) {
              let dat = {
                file_link: response.data.chat_files[i].file_link,
                file_name: response.data.chat_files[i].file_name,
                file_type: response.data.chat_files[i].file_type,
                is_paid: response.data.chat_files[i].is_paid,
                price: response.data.chat_files[i].price,
                id: response.data.chat_files[i].id,
              };
              chatfile.push(dat);
            }
          }
          // this.scrollToBottom();
        }
        //console.log("==chatfile",chatfile)

        this.__websocket.socket.emit('sendMessage', {
          // receiver_id:receiver_id,
          sender_id: senderId._id,
          sent_by_me: false,
          receiver_id: this.selectedUser,
          chat_content: this.chat_content,
          type: response.data.type,
          createdAt: response.data.createdAt,
          chat_files: chatfile,
          id: response.data.id,
          // chat_files: [{
          //   file_link: response.data.chat_files.file_link,
          //   file_name: response.data.chat_files.file_name,
          //   file_type: response.data.chat_files.file_type
          // }]
        });
        this.chat_content = '';
        this.msgTextArea.nativeElement.setAttribute('style', {
          height: this.msgTextArea.nativeElement.scrollHeight + 'px',
        });
        this.sendFile = true;
        this.selectedFiles = [];
        this.images = [];
        this.showEmojiPicker = false;
        this.getChatDetails();
      },
      (err) => {
        this.showError(err.error.message);
        //////console.log(err);
      }
    );
  }

  onFileChanged(event) {
    event.preventDefault();
    //alert("hello");

    for (let i = 0; i < event.target.files.length; i++) {
      let selectedImage = event.target.files[i];
      selectedImage.is_paid = this.tempImgType ? this.tempImgType : 1;
      selectedImage.price = this.price;
      this.selectedFiles.push(selectedImage);
    }
    this.tempImgType = '';
    this.price = 0;
    // this.selectedFiles.push(...event.target.files);
    ////console.log("image length+++",event.target.files.length);
    ////console.log("Total Price ++++",(event.target.files.length * this.price))
    for (let i = 0; i < event.target.files.length; i++) {
      let fname = event.target.files[i].name;
      let extension = fname.substr(fname.lastIndexOf('.') + 1);
      if (extension == 'jpg' || extension == 'jpeg' || extension == 'png') {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (e) => {
          this.images.push({
            src: e.target.result,
            ext: extension,
            fileName: fname,
          });
        };
      } else {
        this.images.push({ src: '', ext: extension, fileName: fname });
      }
    }
    this.sendFile = false;
    this.msgTextArea.nativeElement.focus();
  }

  addEmoji(event) {
    this.chat_content =
      this.chat_content != ''
        ? this.chat_content + event.emoji.native
        : event.emoji.native;
    this.sendFile = false;
    this.msgTextArea.nativeElement.focus();

    // this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    console.log('Check++++++++++', this.showEmojiPicker);
  }
  removeImage(i) {
    this.selectedFiles.splice(i, 1);
    this.images.splice(i, 1);
    if (this.selectedFiles.length == 0) {
      this.sendFile = true;
    }
  }

  async startCall(callToId) {
    //console.log(callToId);
    // this.__calling.ringingToneStart()
    this.remoteUserId = callToId;
    this.remotePlayer =
      this.userProfileDetails.first_name +
      ' ' +
      this.userProfileDetails.last_name;
    // this.yourConn = new RTCPeerConnection({
    //   iceServers: [
    //     {
    //       urls: 'stun:stun.l.google.com:19302',
    //     }, {
    //       urls: 'stun:stun1.l.google.com:19302',
    //     }, {
    //       urls: 'stun:stun2.l.google.com:19302',
    //     }

    //   ],
    // });
    ////console.log(typeof this.jsondata._id, 'this.jsondata._id', typeof callToId);
    this.videoCallInfo.sender_id = this.jsondata._id;
    this.videoCallInfo.receiver_id = callToId;
    this.videoCallInfo.type = 'request';

    let userData = await this.__calling.getRemotePlayerdata(callToId);
    this.__calling.userData = userData;
    this.__calling.channalId = callToId;
    let message1 = {
      sender_id: this.jsondata._id,
      receiver_id: callToId,
      type: 'request',
      userType: userData,
    };
    // //console.log(message1, 'message1..........');

    this.__websocket.socket.emit('videocall', message1);
    // this.setIceconnection();

    // this.__calling.establishMediaCall(this.remoteUserId, this.full_name);

    this.chatListhow = false;
    this.videoCallshow = true;

    this.outgoingCall = true;
    // this.__calling.call_start_time
  }

  clickMicrophoneEvent() {
    this.microphoneToggle =
      !this.__calling.localStreamBs.value.getAudioTracks()[0].enabled;
    // this.microphoneToggle = !this.microphoneToggle;
    // var myVideo: any = document.getElementById('my_video');
    // myVideo.muted = !myVideo.muted;
    this.__calling.muteaudio();
  }
  clickVideoEvent() {
    this.__calling.mutevideo();
  }
  clickcallendEvent(receiver_id, type) {
    console.log('clickcallendEvent', type);

    if (type.trim() != '' && type.trim() != 'request') {
      //alert('close by self');
      this.chatListhow = true;
      this.videoCallshow = false;
      this.__calling.closeMediaCall();
      this.__calling.ringingToneEnd();
      this.__websocket.socket.emit('callEnd');
      const today = new Date();
      this.__calling.call_end_time =
        today.getHours() +
        ':' +
        String(today.getMinutes()).padStart(2, '0') +
        ':' +
        today.getSeconds();
      let message = {
        sender_id: this.jsondata._id,
        receiver_id: receiver_id,
        type: 'leave',
      };

      this.__websocket.socket.emit('videocall', message);

      this.__chat
        .saveCall({
          receiver_id: this.videoCallInfo.receiver_id, //this.__calling.remote_user_id,
          call_start_time: this.callStartTime,
          call_end_time: this.__calling.call_end_time, //12:45:17
        })
        .subscribe(
          (response) => {
            // //////console.log('callsaveresp----------',+)
            ////console.log('callsaveresp----------', this.chatDetails.conversation);
            // this.reload();
            this.getChatDetails();
            this.leave();
            let userInfo = JSON.parse(
              localStorage.getItem('portal_login_data')
            );
            console.log('userInfo==> ', userInfo);
            if (userInfo.user_type == 'CELEBRITY') {
              window.location.reload();
            }
          },
          (err) => {
            //////console.log('ERR----',err)
          }
        );
    } else {
      this.chatListhow = true;
      this.videoCallshow = false;
      this.incomingCall = false;

      this.outgoingCall = false;

      this.__calling.ringingToneEnd();
      if (this.__calling.callingWindow) {
        this.__calling.callingWindow.close();
      } else {
        console.warn('callingWindow is undefined');
      }
      let message = {
        sender_id: this.videoCallInfo.sender_id,
        receiver_id: this.videoCallInfo.receiver_id,
        type: 'reject',
      };
      // this.leave();
      //console.log("caller reject",message)
      this.__websocket.socket.emit('videocall', message);
      // window.location.reload();
    }
  }
  // public endCall() {
  //   this.__calling.closeMediaCall();
  // }

  // translateText(){
  //   //////console.log(this.selectedLang)
  //   this.getChatDetails();

  //   this.chatDetails['conversation'] = this.chatDetails['conversation'].map(x=>{

  //     const googleObj: GoogleObj = {
  //       // q: this.data.title,
  //       q: [x.chat_content],
  //       target: 'es'
  //     };
  //     this.google.translate(googleObj).subscribe(
  //       (res: any) => {
  //       x['chat_content'] = res.data.translations[0].translatedText
  //   })
  //     return x
  //   })
  //   //////console.log("this one-----------",this.chatDetails)
  //   this.chatDetails = []
  //   //////console.log("this two-----------",this.chatDetails)

  //   return
  //   const googleObj: GoogleObj = {
  //     // q: this.data.title,
  //     q: [this.data.title, this.data.description, this.data.detail],
  //     target: 'es'
  //   };
  //   this.google.translate(googleObj).subscribe(
  //     (res: any) => {
  //     this.data = {
  //     title: res.data.translations[0].translatedText,
  //     description: res.data.translations[1].translatedText,
  //     detail: res.data.translations[2].translatedText
  //     };
  //     //////console.log(this.data);
  // })
  // }
  reload() {
    let currentUrl = this.route.url;
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([currentUrl]);
      //console.log(currentUrl);
    });
  }
  public yourConn = new RTCPeerConnection({
    iceServers: [
      {
        urls: 'stun:stun.l.google.com:19302',
      },
      {
        urls: 'stun:stun1.l.google.com:19302',
      },
      {
        urls: 'stun:stun2.l.google.com:19302',
      },
    ],
  });
  async onCall(data) {
    this.yourConn = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
        {
          urls: 'stun:stun1.l.google.com:19302',
        },
        {
          urls: 'stun:stun2.l.google.com:19302',
        },
      ],
    });
    this.yourConn.createOffer(offerOptions).then((offer) => {
      ////console.log('OnCalloffer', offer);
      this.yourConn.setLocalDescription(offer).then(() => {
        ////console.log(data.msg.receiver_id, 'data.msg.sender_id', typeof data.msg.sender_id);
        let message = {
          sender_id: data.msg.receiver_id,
          receiver_id: data.msg.sender_id,
          type: 'offer',
          offer: offer,
        };
        //console.log(message, 'message');

        this.__websocket.socket.emit('videocall', message);

        // Send pc.localDescription to peer
      });
    });
    // try {
    // const offer: RTCSessionDescriptionInit = await this.yourConn.createOffer(offerOptions);
    // // Establish the offer as the local peer's current description.
    // await this.yourConn.setLocalDescription(offer);

    // } catch (err) {
    //   this.handleGetUserMediaError(err);
    // }
  }

  _registerConnectionListeners() {
    this.yourConn.onicecandidate = (event) => {
      if (event.candidate) {
        const payload = {
          type: 'candidate',
          candidate: event.candidate.toJSON(),
        };
        // this.signalingService.sendMessage(payload);
      }
    };
  }

  async setOffer(data) {
    //console.log(JSON.stringify(data), 'line 600');
    // let receiverdata = JSON.stringify(data);
    // //console.log("senderId",data.msg.sender_id)
    this.__user.detailsUser(data.msg.sender_id).subscribe((res: any) => {
      this.senderData = res.data;
    });
    try {
      let callfrom = '';
      this.chatListhow = false;
      this.incomingCall = true;

      this.outgoingCall = true;

      //console.log("incomingCall",this.incomingCall)
      // const conf = confirm(`Incoming call from ${callfrom}`)
      // if (conf) {
      //   const today = new Date();
      //   let startTime = today.getHours() + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + today.getSeconds();
      //   this.__calling.remote_user_id = data.msg.sender_id;
      //   this.__calling.call_start_time = startTime;

      //   // const stream: any = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      //   this.videoCallshow = true;

      //   // setTimeout(() => {
      //   //   this.ringingToneEnd();

      //   //   // ////console.log(stream, "stream-local", this.__calling.elebelcallflag)
      //   //   this.localVideo.nativeElement.srcObject = stream;

      //   // }, 500);

      //   // this.yourConn.addStream(stream);

      //   // this.yourConn.onaddstream = event => {
      //   //   ////console.log('On Add Stream', event.stream);
      //   //   if (this.remoteVideo) {
      //   //     this.remoteVideo.nativeElement.srcObject = event.stream
      //   //   }
      //   // };

      //   // let offerdata = JSON.parse(localStorage.getItem('offer'));
      //   await this.yourConn.setRemoteDescription(new RTCSessionDescription(data.msg.offer));
      //   // Setup ice handling

      //   let answer = await this.yourConn.createAnswer();

      //   await this.yourConn.setLocalDescription(answer);
      //   let message = { sender_id: data.msg.receiver_id, receiver_id: data.msg.sender_id, type: 'answer', answer: answer }
      //   //console.log(answer, 'Sending answer===>', message);
      //   this.__websocket.socket.emit('videocall', message);
      //   // this.setIceconnection();

      // } else {
      //   let message = { sender_id: data.msg.receiver_id, receiver_id: data.msg.sender_id, type: 'not-answered' }

      //   this.__websocket.socket.emit('videocall', message);

      //   this.ringingToneEnd();
      //   this.receiverDiv = false;

      // }
    } catch (err) {
      ////console.log('Answer Error', err);
    }
  }

  async handleAnswer(data) {
    // //console.log(data);

    await this.yourConn.setRemoteDescription(new RTCSessionDescription(data));
    //console.log('videostrdata');

    this.chatListhow = false;
    this.videoCallshow = true;
    try {
      // this.videoCallshow = true;

      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then((mediaStream) => {
          const stream = mediaStream;
          this.tracks = stream.getTracks();
          //console.log(this.tracks)
          this.yourConn.addStream(stream);
          // stream.getTracks().forEach((track) => {
          //   this.yourConn.addTrack(track, stream);

          // });
          this.localVideo.nativeElement.srcObject = stream;
          //   // setTimeout(() => {

          //     //console.log(stream, "stream-local")
          //     // if (this.localVideo) {

          //     // }

          //   // }, 500);
        });

      // this.yourConn.onaddstream = (event) => {
      //   //console.log('On Add Stream', event.stream);
      //   if (this.remoteVideo) {
      //     // setTimeout(() => {
      //       this.remoteVideo.nativeElement.srcObject = event.stream

      //     // }, 500);
      //   }
      // };

      // // Setup ice handling
      // this.yourConn.onicecandidate = async (event) => {
      //   if (event.candidate) {

      //     let message = { sender_id: data.msg.receiver_id, receiver_id: data.msg.sender_id, type: 'candidate', candidate: event.candidate }
      //     ////console.log('sending candidate==>', message);
      //     this.__websocket.socket.emit('videocall', message);

      //   }
      // };
      // this.setIceconnection();
    } catch (err) {}
  }

  handleCandidate(data) {
    //console.log(data,'recived candidate');

    this.yourConn.addIceCandidate(new RTCIceCandidate(data));
  }
  async getMessages(data, conn) {
    // this.__calling.getMessages();

    this.setOffer(data);
  }

  handelClosed() {
    // setOffer(null);
    // this.localVideo.stop();
    if (this.tracks && this.tracks.length > 0) {
      this.tracks[1].enabled = false;
    }

    this.videoCallshow = false;
    // localStorage.removeItem('offer')
    this.yourConn.close();

    if (this.yourConn) {
      // this.yourConn = null;
      // this.reload();
    }

    // this.yourConn.close();
  }

  ngOnDestroy(): void {
    this.__calling.destroyPeer();
    this.subscription.unsubscribe();
    this.__calling.onCallScreenBlur$.next(true);
  }
  public ringingToneEnd() {
    this.ringingAudio.pause();
  }

  //accept incoming call

  openCallingWindow(channalId) {
    let externalURL = `${environment.url}/#/user/calling`;
    externalURL += `?channalId=${channalId}`;
    externalURL += `&remotePlayerType=${this.remotePlayerType}`;
    this.__calling.isCallStartedBs.next(true);
    const windowParams =
      'width=800,height=600, location=no, menubar=no, status=no, toolbar=no';
    this.__calling.callingWindow = window.open(
      externalURL,
      '_blank',
      windowParams
    );
    this.__calling.callingWindow.addEventListener('load', () => {
      this.noOfWindowOppened += 1;
      if (
        this.noOfWindowOppened === 1 &&
        this.videoCallInfo.receiver_id === channalId
      ) {
        let message = {
          sender_id: this.videoCallInfo.receiver_id,
          receiver_id: this.videoCallInfo.sender_id,
          type: 'answer',
        };
        this.__websocket.socket.emit('videocall', message);
      }
    });
    this.__calling.callingWindow.onbeforeunload = (e) => {
      this.__calling.isCallStartedBs.next(false);
      window.location.reload();
    };
  }

  acceptCall(data) {
    this.ringTime = null;
    this.incomingCall = false;
    this.outgoingCall = false;
    this.videoCallshow = false;
    this.chatListhow = false;
    this.__calling.acceptedCall();
    localStorage.setItem('videoCallInfo', JSON.stringify(this.videoCallInfo));
    localStorage.setItem(
      'calScheduledata',
      JSON.stringify(this.calScheduledata)
    );
    this.openCallingWindow(this.videoCallInfo.receiver_id);
    // this.__user
    //   .detailsUser(this.videoCallInfo.sender_id)
    //   .subscribe((response) => {
    //     this.remotePlayer =
    //       response.data.first_name + ' ' + response.data.last_name;
    //     this.remotePlayerType = response.data.user_type;
    //     console.log('remote-player name', this.remotePlayer);
    //   });
    // let today = new Date();
    // let startTime =
    //   today.getHours() +
    //   ':' +
    //   String(today.getMinutes()).padStart(2, '0') +
    //   ':' +
    //   today.getSeconds();
    // this.callStartTime = startTime;
    // this.ringTime = null;
    // this.incomingCall = false;

    // this.outgoingCall = false;

    // this.videoCallshow = true;
    // this.chatListhow = false;
    // this.videoCallInfo.type = 'answer';
    // this.__calling.ringingToneEnd();
    // this.join(this.videoCallInfo.receiver_id);
    // let message1 = {
    //   sender_id: this.videoCallInfo.receiver_id,
    //   receiver_id: this.videoCallInfo.sender_id,
    //   type: 'answer',
    // };
    // this.__websocket.socket.emit('videocall', message1);
    // // this.__calling.callingEvent$.next({msg: message1});
    // this.__calling.setCallConnected();
    // localStorage.setItem('callData', JSON.stringify(this.videoCallInfo));
  }

  //below section for incoming call receive and reject
  rejectCall(id) {
    this.ringTime = null;
    this.incomingCall = false;

    this.outgoingCall = false;

    this.videoCallshow = false;
    this.chatListhow = true;
    this.__calling.ringingToneEnd();
    let message1 = {
      sender_id: this.videoCallInfo.receiver_id,
      receiver_id: this.videoCallInfo.sender_id,
      type: 'reject',
    };
    this.__websocket.socket.emit('videocall', message1);
    localStorage.removeItem('channelId');
  }

  rateingTips() {
    console.log(
      this.userProfileDetails,
      'userProfileDetails',
      this.selectedUser
    );

    // if (this.userProfileDetails.user_type == "CELEBRITY") {
    if (this.remoteUserInfo.user_type == 'CELEBRITY') {
      const dialogReff = this.dialog.open(DialogRaingTipsDialogs, {
        data: { data: this.remoteUserInfo, scheduledata: this.calScheduledata },
        panelClass: 'testclss',
      });
      dialogReff.afterClosed().subscribe((result) => {
        ////console.log('The dialog was closed');
      });
    }
  }

  checkSchedule(selectedUser) {
    //debugger
    let currentTime = Math.floor(new Date().getTime() / 1000.0);
    // ////console.log(selectedUser, 'selectedUser', this.userProfileDetails);
    this.__user.checkSchedule(selectedUser).subscribe((response) => {
      if (Object.keys(this.userProfileDetailsParent).length > 0) {
        if (
          this.userProfileDetailsParent.user_type === 'CELEBRITY' &&
          this.userProfileDetailsParent
        ) {
          ////console.log(response, this.userProfileDetailsParent.user_type, this.userProfileDetailsParent);
          this.placeholder_data =
            "You Don't have any Schedule With " +
            ' ' +
            this.userProfileDetailsParent.first_name +
            ' ' +
            this.userProfileDetailsParent.last_name;

          if (response.data.length == 0) {
            this.disableChat = true;
          }
          if (response.data.length > 0) {
            for (const key in response.data) {
              const LocalStartTime: any = moment
                .utc(response.data[key].start_time)
                .local()
                .format('HH:mm');
              response.data[key].newStartTime = LocalStartTime;
              const [hours, minutes] =
                response.data[key].newStartTime.split(':');
              let newStartDate = new Date();
              newStartDate.setHours(hours);
              newStartDate.setMinutes(minutes);
              // let newStartDate = new Date(response.data[key].newStartTime);
              response.data[key].newStartTime = Math.floor(
                newStartDate.getTime() / 1000.0
              );
              const LocalEndTime: any = moment
                .utc(response.data[key].end_time)
                .local()
                .format('HH:mm');
              response.data[key].newEndTime = LocalEndTime;
              // response.data[key].date + 'T' + response.data[key].end_time;

              // let newEndDate = new Date(response.data[key].newEndTime);
              const [hours1, minutes1] =
                response.data[key].newEndTime.split(':');
              let newEndDate = new Date();
              newEndDate.setHours(hours1);
              newEndDate.setMinutes(minutes1);
              response.data[key].newEndTime = Math.floor(
                newEndDate.getTime() / 1000.0
              );
              ////console.log(currentTime, 'currentTime', response.data[key].newStartTime, 'response.data[key].newStartTime', response.data[key].newEndTime, 'newEndTime');

              if (
                currentTime >= response.data[key].newStartTime &&
                currentTime <= response.data[key].newEndTime
              ) {
                if (
                  response.data[key].type &&
                  response.data[key].type == 'CHAT'
                ) {
                  this.disableChat = false;
                  // this.enableCall = false;
                }
                //debugger
                if (
                  response.data[key].type &&
                  response.data[key].type == 'CALL'
                ) {
                  //console.log('line 940');

                  this.enableCall = true;
                  this.calScheduledata = response.data[key];
                  this.__calling.calScheduledata = { ...this.calScheduledata };
                  // this.disableChat = false;
                }
              } else {
                this.disableChat = true;

                this.enableCall = false;
                this.calScheduledata = {};
              }
            }
          }
        } else if (
          this.userProfileDetailsParent.user_type !== 'CELEBRITY' &&
          this.userProfileDetailsParent
        ) {
          ////console.log(response, this.userProfileDetailsParent.user_type, this.userProfileDetailsParent);
          this.placeholder_data =
            "You Don't have any Schedule With " +
            ' ' +
            this.userProfileDetailsParent.first_name +
            ' ' +
            this.userProfileDetailsParent.last_name;

          if (response.data.length == 0) {
            this.disableChat = true;
          }
          if (response.data.length > 0) {
            for (const key in response.data) {
              const LocalStartTime: any = moment
                .utc(response.data[key].start_time)
                .local()
                .format('HH:mm');
              response.data[key].newStartTime = LocalStartTime;
              // response.data[key].date + 'T' + response.data[key].start_time;

              const [hours, minutes] =
                response.data[key].newStartTime.split(':');
              let newStartDate = new Date();
              newStartDate.setHours(hours);
              newStartDate.setMinutes(minutes);
              // let newStartDate = new Date(response.data[key].newStartTime);
              response.data[key].newStartTime = Math.floor(
                newStartDate.getTime() / 1000.0
              );
              const LocalEndTime: any = moment
                .utc(response.data[key].end_time)
                .local()
                .format('HH:mm');
              response.data[key].newEndTime = LocalEndTime;
              // response.data[key].date + 'T' + response.data[key].end_time;

              // let newEndDate = new Date(response.data[key].newEndTime);
              const [hours1, minutes1] =
                response.data[key].newEndTime.split(':');
              let newEndDate = new Date();
              newEndDate.setHours(hours1);
              newEndDate.setMinutes(minutes1);
              response.data[key].newEndTime = Math.floor(
                newEndDate.getTime() / 1000.0
              );
              ////console.log(currentTime, 'currentTime', response.data[key].newStartTime, 'response.data[key].newStartTime', response.data[key].newEndTime, 'newEndTime');

              if (
                currentTime >= response.data[key].newStartTime &&
                currentTime <= response.data[key].newEndTime
              ) {
                if (
                  response.data[key].type &&
                  response.data[key].type == 'CHAT'
                ) {
                  this.disableChat = false;
                  // this.enableCall = false;
                }
                //debugger
                if (
                  response.data[key].type &&
                  response.data[key].type == 'CALL'
                ) {
                  //console.log('line 940');

                  this.enableCall = true;
                  this.calScheduledata = response.data[key];
                  this.__calling.calScheduledata = { ...this.calScheduledata };
                  // this.disableChat = false;
                }
              } else {
                this.disableChat = true;

                this.enableCall = false;
                this.calScheduledata = {};
              }
            }
          }
        } else {
          this.disableChat = false;
          //console.log('line 940');

          this.enableCall = true;
          ////console.log('inelsePart');
          this.calScheduledata = {};
        }
        this.__calling.calScheduledata = { ...this.calScheduledata };
      }
    });
  }

  showCallMessage(val) {
    //debugger
    ////console.log(this.userProfileDetails);

    if (this.onlineUsers.includes(this.userProfileDetails._id)) {
      if (!this.enableCall) {
        this.toastr.warning(
          "You Don't Have any Schedule With" +
            ' ' +
            this.userProfileDetails.first_name +
            ' ' +
            this.userProfileDetails.last_name
        );
      }
    } else {
      this.toastr.warning(
        this.userProfileDetails.first_name +
          ' ' +
          this.userProfileDetails.last_name +
          ' Is Offline'
      );
    }
    // this.join(this.userProfileDetails._id)
  }

  async setIceconnection() {
    this.tracks = '';
    let count = 1;

    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        const stream = mediaStream;
        this.tracks = stream.getTracks();
        // this.yourConn.addStream(stream);
        stream.getTracks().forEach((track) => {
          this.yourConn.addTrack(track, stream);
        });
        // //console.log(stream, 'joydeepda say');
        // setTimeout(() => {

        // ////console.log(stream, "stream-local", this.__calling.elebelcallflag)
        if (this.localVideo) {
          this.localVideo.nativeElement.srcObject = stream;
        }

        // }, 500);
      });
    // //console.log(this.tracks);
    setTimeout(() => {
      this.yourConn.ontrack = async (event) => {
        //console.log('On Add Stream', event);
        if (this.remoteVideo) {
          this.remoteVideo.nativeElement.srcObject = event.stream;
        }
      };
    }, 500);

    // Setup ice handling
    this.yourConn.onicecandidate = async (event) => {
      if (event.candidate) {
        let message = {
          sender_id: this.jsondata._id,
          receiver_id: this.selectedUser,
          type: 'candidate',
          candidate: event.candidate,
        };
        //console.log('sending candidate==>', message);
        this.__websocket.socket.emit('videocall', message);
      }
    };
  }

  callDisconnect(val) {
    if (val) {
      let newEndDate = new Date();
      val.currentTime = Math.floor(newEndDate.getTime() / 1000.0);
      //console.log(val);
      if (
        val.currentTime >= val.newEndTime &&
        this.sender_id == val.scheduled_with
      ) {
        //console.log('CutCall');
        this.calScheduledata = {};
        this.clickcallendEvent(this.sender_id, '');
      }
    }
  }
}

@Component({
  selector: 'dialog-animations-example-dialogs',
  templateUrl: 'dialog-raing-tips-dialogs.html',
  styleUrls: ['./modal.css'],
})
export class DialogRaingTipsDialogs {
  public modalData: any;
  revisedScheduleFlag = false;
  editFlag = false;
  schedule: any = {};
  public ratingTips: FormGroup;
  public ratingArr = [];
  rating: number;
  rating1: number;
  tips: any = [];
  tips_value: any;
  tipsdata: any = 0;
  rateing: boolean = true;
  tipsFlag: boolean = false;
  userProfileImage: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogReff: MatDialogRef<DialogRaingTipsDialogs>,
    private toastr: ToastrService,
    private __user: UserService
  ) {
    console.log('ratingDataABCCC   ', data);
    this.rating = 0;
    this.rating1 = 0;
    this.modalData = data.data;
    // this.modalData.profile_image = 'https://nodeserver.mydevfactory.com:3020/' + this.modalData.profile_image;
    this.modalData.profile_image = this.modalData.profile_image;
    this.userProfileImage = `url('${this.modalData.profile_image}')`;
    this.schedule = data.scheduledata;
    this.tips = [
      { data: '1', value: 1 },
      { data: '5', value: 5 },
      { data: '10', value: 10 },
      { data: '15', value: 15 },
      { data: '20', value: 20 },
      { data: '25', value: 25 },
      { data: '30', value: 30 },
    ];
    console.log('modalData ', this.modalData);
  }
  onItemChange(value) {
    // console.log("value================>> ",value)
    this.rating = value;
  }
  onSubmit() {
    let data: any = {
      celebrity_id: this.modalData._id,
      rating: this.rating,
      schedule: this.schedule._id,
    };
    this.__user.rateingInfo(data).subscribe((response) => {
      ////console.log(response);
      this.showSuccess(response.message);
      this.rateing = false;
      this.tipsFlag = true;
      this.dialogReff.close();
      window.location.reload();
    });
  }
  onClick(val, index) {
    ////console.log(val);
    for (const key in this.tips) {
      this.tips[key].isSelect = false;
    }
    this.tips[index].isSelect = true;
    this.tipsdata = val.value;
  }
  showSuccess(msg) {
    this.toastr.success(msg, 'Success!');
  }
  inputTips(event) {
    ////console.log(event, this.tips_value);
    this.tipsdata = this.tips_value;
  }
  // { "id": "611cf5b717dabc2b213df2cb",
  // "amount" : "10"
  //        }
  tipsGiven() {
    if (this.tipsdata > 0) {
      let data: any = {
        id: this.modalData._id,
        amount: this.tipsdata,
      };
      this.__user.rateingInfo(data).subscribe((response) => {
        ////console.log(response);
        this.showSuccess(response.message);
        this.dialogReff.close();
        window.location.reload();
      });
    } else {
      this.toastr.warning('Enter a valid Ammount');
    }
  }
  refrshScreen() {
    window.location.reload();
  }
}

@Component({
  selector: 'dialog-animations-example-dialog',
  templateUrl: 'dialog-raing-tips-dialog.html',
  styleUrls: ['./modal.css'],
})
export class DialogRaingTipsDialog {
  public modalData: any;
  revisedScheduleFlag = false;
  editFlag = false;
  schedule: any = {};
  public ratingTips: FormGroup;
  public ratingArr = [];
  rating: number;
  rating1: number;
  tips: any = [];
  tips_value: any;
  tipsdata: any = 0;
  rateing: boolean = true;
  tipsFlag: boolean = false;
  userProfileImage: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<DialogRaingTipsDialog>,
    private toastr: ToastrService,
    private __user: UserService
  ) {
    // //console.log(data);
    this.rating = 0;
    this.rating1 = 0;
    this.modalData = data.data;
    // this.modalData.profile_image = 'https://nodeserver.mydevfactory.com:3020/' + this.modalData.profile_image;
    this.userProfileImage = `url('${this.modalData.profile_image}')`;
    this.schedule = data.scheduledata;
    this.tips = [
      { data: '1', value: 1 },
      { data: '5', value: 5 },
      { data: '10', value: 10 },
      { data: '15', value: 15 },
      { data: '20', value: 20 },
      { data: '25', value: 25 },
      { data: '30', value: 30 },
    ];
  }
  onSubmit() {
    let data: any = {
      celebrity_id: this.modalData._id,
      rating: this.rating,
      schedule: this.schedule._id,
    };
    this.__user.rateingInfo(data).subscribe((response) => {
      ////console.log(response);
      this.showSuccess(response.message);
      this.rateing = false;
      this.tipsFlag = true;
    });
  }
  onClick(val, index) {
    ////console.log(val);
    for (const key in this.tips) {
      this.tips[key].isSelect = false;
    }
    this.tips[index].isSelect = true;
    this.tipsdata = val.value;
  }
  showSuccess(msg) {
    this.toastr.success(msg, 'Success!');
  }
  inputTips(event) {
    ////console.log(event, this.tips_value);
    this.tipsdata = this.tips_value;
  }
  // { "id": "611cf5b717dabc2b213df2cb",
  // "amount" : "10"
  //        }
  tipsGiven() {
    // if (this.tipsdata > 0) {
    //   let data: any = {
    //     "id": this.modalData._id,
    //     "amount": this.tipsdata
    //   }
    //   this.__user.rateingInfo(data).subscribe(
    //     (response) => {
    //       ////console.log(response);
    //       this.showSuccess(response.message);
    //       this.dialogRef.close();
    //       // window.location.reload()
    //     })
    // } else {
    //   this.toastr.warning('Enter a valid Ammount');
    // }

    if (this.tipsdata > 0) {
      let tipsData: any = {
        id: this.modalData._id,
        amount: this.tipsdata,
      };
      console.log('tipsInfo', tipsData);
      this.__user.giveingtips(tipsData).subscribe((response) => {
        if (response.success == true) {
          console.log(response);
          this.showSuccess(response.message);
          this.dialogRef.close();
          // window.location.reload()
        } else {
          this.toastr.warning(response.message);
        }
      });
    } else {
      this.toastr.warning('Enter a valid Amount');
    }
  }
  refrshScreen() {
    // window.location.reload()
  }
}

// sending tips dialog box

@Component({
  selector: 'send-tips-selector',
  templateUrl: 'send-tips.html',
  styleUrls: ['./modal.css'],
})
export class SendTipsDialog {
  public modalData: any;
  revisedScheduleFlag = false;
  editFlag = false;
  schedule: any = {};
  public ratingTips: FormGroup;
  public ratingArr = [];
  rating: number;
  rating1: number;
  demodata: number;
  tipsd: any = [];
  tips_values: number;
  tipsdatas: any = 0;
  rateing: boolean = true;
  tipsFlag: boolean = false;
  userProfileImage: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SendTipsDialog>,
    private toastr: ToastrService,
    private __user: UserService
  ) {
    // //console.log(data);
    this.rating = 0;
    this.rating1 = 0;
    this.modalData = data.data;
    this.modalData.profile_image =
      'https://hi2u.app:3020/' + this.modalData.profile_image;
    this.userProfileImage = `url('${this.modalData.profile_image}')`;
    this.schedule = data.scheduledata;
    this.demodata = 6;
    this.tipsd = [
      { data: '1', value: 1 },
      { data: '5', value: 5 },
      { data: '10', value: 10 },
      { data: '20', value: 20 },
      { data: '50', value: 50 },
    ];
  }
  onSubmit() {
    let data: any = {
      celebrity_id: this.modalData._id,
      rating: this.rating,
      schedule: this.schedule._id,
    };
    this.__user.rateingInfo(data).subscribe((response) => {
      ////console.log(response);
      this.showSuccess(response.message);
      this.rateing = false;
      this.tipsFlag = true;
    });
  }
  onClick(val, index) {
    ////console.log(val);
    for (const key in this.tipsd) {
      this.tipsd[key].isSelect = false;
    }
    this.tipsd[index].isSelect = true;
    this.tipsdatas = val.value;
  }
  showSuccess(msg) {
    this.toastr.success(msg, 'Success!');
  }
  inputTipst(event) {
    console.log('check', this.tips_values);
    this.tipsdatas = this.tips_values;
  }
  // { "id": "611cf5b717dabc2b213df2cb",
  // "amount" : "10"
  //        }
  tipssends() {
    console.log('datasstt', this.tips_values);
    if (this.tipsdatas > 0) {
      let data: any = {
        id: this.modalData._id,
        amount: this.tipsdatas,
      };
      this.__user.rateingInfo(data).subscribe((response) => {
        ////console.log(response);
        this.showSuccess(response.message);
        this.dialogRef.close();
        // window.location.reload()
      });
    } else {
      this.toastr.warning('Enter a valid Ammount');
    }
  }
  refrshScreen() {
    this.dialogRef.close();
  }
}

// @Component({
//   selector: 'pop-up',
//   templateUrl: './pop-up.component.html',
//   styleUrls: ['./modal.css']

// })

// export class PopUp{

//   public dialog: MatDialog
//   public dialog3: MatDialog

//   constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef1: MatDialogRef<PopUp>,public dialogRef2: MatDialogRef<PopUp>, private toastr: ToastrService, private __user: UserService,private location: Location) {
//   }

//   cancel() {
//     this.location.back(); // <-- go back to previous location on cancel
//   }

//   close(){
//     this.dialogRef1.close();

//   }

// }

@Component({
  selector: 'openpopup-selector',
  templateUrl: './openpopup.html',
  styleUrls: ['./modal.css'],
})
export class Openpopup {
  public sample: any;

  type: number;
  typecheck: boolean = false;
  ispaid: number;
  price: number;

  @ViewChild('fileInput', { static: true }) usernameElement: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef3: MatDialogRef<Openpopup>,
    public dialogRef4: MatDialogRef<Openpopup>,
    private toastr: ToastrService,
    private __user: UserService
  ) {}

  getvalue(value) {
    if (this.type == 0) {
      let d: any = {
        ispaid: 0,
        price: 0,
      };

      //console.log("free image",d);
      this.dialogRef3.close();

      // next part to select image
    } else {
      //console.log("paid image");
      this.typecheck = true;
      this.getprice();

      // next part to provide amount
    }
  }

  getprice() {
    //console.log("price", this.price)
  }

  onFileChanged(event) {
    const target = event.target as HTMLInputElement;
    //console.log(target);
    if (target.files && target.files.length > 0) {
      //console.log(target.files[0].name);
    }
  }
}
